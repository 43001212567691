.modal-backdrop {
  &.show {
    opacity: .5;
  }
  ~ .modal-backdrop {
    opacity: 0;
  }
}

.modal-header button.close {
  opacity: 1;
}

.modal {
  &.show {
    opacity: 1;

    .modal-dialog {
      transform: translate(0,0);
    }
  }
}
