.pagination-wrapper {
    text-align: center;
    ul.pagination {
        li {
            display: inline-block;
            margin: 0 5px;
            span, a {
                background: none;
                border: none;
                color: #777777;
            }
            &.disabled, &.disabled:hover {
                span, a {
                    background: none;
                    color: #777777;
                }
            }
            &.active, &.active:hover {
                span, a {
                    background: @btn-consentz-bg-color-dark-blue;
                    color: #ffffff!important;
                }
            }
            &:first-child, &:last-child {
                span, a {
                    color: inherit;
                }
            }

        }
    }
}
