#render-block-report {
  .highcharts-data-table {
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 20px 0;
    table {
      padding: 5px;
      background-color: white;
      overflow: auto;
      width: 100%;
      vertical-align: top;
      max-width: 100%;
      margin-bottom: @line-height-computed;
      // Cells
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            padding: @table-cell-padding;
            line-height: @line-height-base;
            vertical-align: top;
            border-top: 1px solid @table-border-color;
          }
        }
      }
      // Bottom align for column headings
      > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid @table-border-color;
      }
      // Remove top border from thead by default
      > caption + thead,
      > colgroup + thead,
      > thead:first-child {
        > tr:first-child {
          > th,
          > td {
            border-top: 0;
          }
        }
      }
      // Account for multiple tbody instances
      > tbody + tbody {
        border-top: 2px solid @table-border-color;
      }
    }
  }
}
#render-block {
  .highcharts-data-table {
    display: inline-block;
    width: 33.3333333%;
    height: 480px;
    margin: 5px 0;
    padding: 0 15px;
    table {
      padding: 5px;
      background-color: white;
      overflow: auto;
      width: 100%;
      max-width: 100%;
      margin-bottom: @line-height-computed;
      // Cells
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            padding: @table-cell-padding;
            line-height: @line-height-base;
            vertical-align: top;
            border-top: 1px solid @table-border-color;
          }
        }
      }
      // Bottom align for column headings
      > thead > tr > th {
        vertical-align: bottom;
        border-bottom: 2px solid @table-border-color;
      }
      // Remove top border from thead by default
      > caption + thead,
      > colgroup + thead,
      > thead:first-child {
        > tr:first-child {
          > th,
          > td {
            border-top: 0;
          }
        }
      }
      // Account for multiple tbody instances
      > tbody + tbody {
        border-top: 2px solid @table-border-color;
      }
    }
  }
}
