.board {
  position: relative;
  margin-left: 0.5%;
}
.board-column {
  position: absolute;
  left: 0;
  right: 0;
  //width: 30%;
  margin: 0 0.8%;
  //background: #f0f0f0;
  border: 1px solid lightgrey;
  border-radius: 3px;
  z-index: 1;
}
.board-column.muuri-item-releasing {
  z-index: 2;
}
.board-column.muuri-item-dragging {
  z-index: 3;
  cursor: move;
}
.board-column-header {
  position: relative;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  padding: 0 20px;
  background: @consentz-main-color;
  color: #fff;
  border-radius: 3px 3px 0 0;
  .board-actions-btn {
    .btn-link {
      color: white;
    }
  }
}
@media (max-width: 600px) {
.board-column-header {
    text-indent: -1000px;
  }
}
.board-column-content {
  position: relative;
  border: 6px solid transparent;
  min-height: 95px;
  max-height: 350px;
  overflow-y: auto;
}
.board-column-footer {
  position: relative;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  border-top: 1px solid lightgrey;
  a {
    color: grey;
  }
}
.board-item {
  position: absolute;
  width: 100%;
  margin: 5px 0;
}
.board-item.muuri-item-releasing {
  z-index: 9998;
}
.board-item.muuri-item-dragging {
  z-index: 9999;
  cursor: move;
}
.board-item.muuri-item-hidden {
  z-index: 0;
}
.board-item-content {
  position: relative;
  padding: 20px;
  background: #fff;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  .task-title {
    display: block;
    margin-top: 2px;
    margin-bottom: 2px;
    border-bottom: 1px solid grey;
    .task-title-date {
      &.passed-date {
        color: red;
      }
    }
  }
  .task-description {
    display: block;
  }
}
@media (max-width: 600px) {
.board-item-content {
    text-align: center;
  }
.board-item-content span {
    display: none;
  }
}

/* Sections */

.section {
  margin: 60px auto;
  padding-top: 0;
  max-width: 100vm;
  //border-top: 2px solid #e5e5e5;
  text-align: left;
}
.section-title {
  margin: 30px 0 15px 18px;
  span {
    //position: relative;
    display: inline-block;
    margin-right: 10px;
    a {
      color: black;
      font-weight: normal;
      &.active {
        text-decoration: underline;
      }
    }
    //vertical-align: top;
  }
}

.colorpicker.colorpicker-visible {
  z-index: 9999;
}
.task-board {
  position: relative;

  #new-board-btn {
    position: absolute;
    display: block;
    top: 74px;
    right: -30px;
    width: 34px;
    height: 34px;
    border: 1px solid @consentz-main-color;
    color: @consentz-main-color;
  }
}