.select2, .select2-search__field {
  width: 100% !important;
}
.calendar-render {
  .select2 {
    max-width: 270px;
  }
}
.modal-open {
  .select2 {
    z-index: 1021 !important;
  }
  .modal-body {
    .select2-container {
      z-index: 1721 !important;
    }
  }
}
.select2-results__option[aria-disabled=true],
.select2-results__option[disabled]
{
  display:none
}
