.highed-chart-template-thumbnail {
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100% auto;
    cursor: pointer;
    color: #fafafa;
    background-position: middle middle;
    box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;

    &:hover {
        border: 5px solid #61BC7B !important;
    }

    &-img {
        width: 100%;
        max-height: 100%;
    }
}

.highed-chart-active {
    border: 5px solid #61BC7B !important;
}

.favorite {
    span {
        cursor:pointer;
    }
}

.template-chart {
    background: #333;
    padding: 20px;
    color: white;
}

.clear-report > a {
    width: 200px;
    margin-left: 31px;
    margin-top: 31px;
}

.custom-report-index {
    width: 350px;
}

.custom-report-desc {
    line-height: 19px!important;
    width: auto !important;
    display: inline-table !important;
    padding-top: 20px;
}
