.choices-practitioners {
    margin-top: 10px;
    margin-bottom: 10px;
}

.filter-wrapper {
    text-align: center;
    clear: both;
}

.chosen-filter-wrapper {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: left;
    width: 57%;
}

.chosen-filter-wrapper-centered {
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
}

.chosen-container {
    ul {
        &.chosen-choices {
            border: 1px solid #C7C7C7;
            background: white url("/images/icon_dropdown_1.png") no-repeat right 12px center;
            background-size: 6px;

            li {
                &.search-choice {
                    height: 30px;
                    margin: 4px;

                    span {
                        line-height: 24px;
                        margin: 0 12px 0 4px;
                    }

                    a {
                        &.search-choice-close {
                            right: 8px !important;
                            top: 7px !important;
                            width: 16px !important;
                            height: 16px !important;
                            background: url("/images/icon_close.png") no-repeat center / 80% !important;
                        }
                    }
                }
            }

            .search-choice + .search-field {
                margin-top: 5px;
            }
        }
    }

    .chosen-drop {
        ul {
            &.chosen-results {
                border: 1px solid #C7C7C7;

                li {
                    border-top: 1px solid #C7C7C7;
                }

                &:first-child {
                    border-top: none;
                }
            }
        }
    }
}
.header-clinics {
    .chosen-container {
        .chosen-choices {
            background-color: #fff;
            .search-field {
                input {

                }
            }
        }
    }
}
li[data-status] {
    padding-left: 12px;
    background: url("../../images/patient-status/questionblue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
    a {
        background-color: transparent;
        outline: none;
        &:hover, &:focus {
            background-color: transparent;
            outline: none;
        }
    }
}
.patient-status-image {
    width: 44px;
    height: 44px;
    display: inline-block;
    background: url("../../images/patient-status/questionblue.png") no-repeat scroll left center transparent;
    background-size: 26px;
}
.patient-status-image-ajax {
    width: 34px;
    height: 25px;
    display: inline-block;
    background: url("../../images/patient-status/questionblue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
.patient-status-image-ajax-input {
    width: 34px;
    height: 25px;
    display: inline-block;
    user-select: none;
    position: absolute;
    top: 4px;
    left: 5px;
    cursor: pointer;
}
.patient-status-name {
    display: block;
    margin-top: -22px;
    padding-left: 34px;
}
span[data-status="Unrated Customer"], input[data-status="Unrated Customer"] {
    background: url("../../images/patient-status/questionblue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
span[data-status="Unhappy Customer"], input[data-status="Unhappy Customer"] {
    background: url("../../images/patient-status/unhappyblue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
span[data-status="null"], input[data-status="null"] {
    background: url("../../images/patient-status/questionblue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
span[data-status="Bad Customer"], input[data-status="Bad Customer"] {
    background: url("../../images/patient-status/bad_customer_blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
span[data-status="Prospect Customer"], input[data-status="Prospect Customer"] {
    background: url("../../images/patient-status/prospect-blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
span[data-status="Valued Customer"], input[data-status="Valued Customer"] {
    background: url("../../images/patient-status/valued-blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
span[data-status="Highly Valued Customer"], input[data-status="Highly Valued Customer"] {
    background: url("../../images/patient-status/highly_valued-blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
li[data-status="Unrated Customer"] {
    background: url("../../images/patient-status/questionblue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
.patient-status-image[data-status="Unrated Customer"] {
    background: url("../../images/patient-status/questionblue.png") no-repeat scroll 1% 50% transparent;
    background-size: 26px;
}
li[data-status="Unhappy Customer"] {
    background: url("../../images/patient-status/unhappyblue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
.patient-status-image[data-status="Unhappy Customer"] {
    background: url("../../images/patient-status/unhappyblue.png") no-repeat scroll 1% 50% transparent;
    background-size: 26px;
}
li[data-status="Bad Customer"] {
    background: url("../../images/patient-status/bad_customer_blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
.patient-status-image[data-status="Bad Customer"] {
    background: url("../../images/patient-status/bad_customer_blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 26px;
}
li[data-status="Prospect Customer"] {
    background: url("../../images/patient-status/prospect-blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
.patient-status-image[data-status="Prospect Customer"] {
    background: url("../../images/patient-status/prospect-blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 26px;
}
li[data-status="Valued Customer"] {
    background: url("../../images/patient-status/valued-blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
.patient-status-image[data-status="Valued Customer"] {
    background: url("../../images/patient-status/valued-blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 26px;
}
li[data-status="Highly Valued Customer"] {
    background: url("../../images/patient-status/highly_valued-blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 20px;
}
.patient-status-image[data-status="Highly Valued Customer"] {
    background: url("../../images/patient-status/highly_valued-blue.png") no-repeat scroll 1% 50% transparent;
    background-size: 26px;
}
.bootstrap-select {
    .dropdown-menu {
        //&.open {
        //    min-width: auto!important;
        //}
        li {
            a:hover {
                background-color: @consentz-main-color;
            }
            &.active {
                a {
                    background-color: @consentz-main-color;
                }
            }
        }
        .glyphicon.glyphicon-ok.check-mark {
            color: @consentz-main-color;
        }
    }
}
.chosen-container-multi .chosen-choices .search-field .default {
    width: auto!important;
}
.signature-to-btn {
    display: inline-block;
    margin-left: 10px;
    margin-right: 30px;
    text-decoration: none;
}

.d-flex-center {
    .signature-to-btn {
        margin: 0 0 0 10px;
    }
}

.signature-to-archive {
    color: @consentz-main-color;
    display: inline-block;
    line-height: 32px;
}
