.round-borders-shadow {
  border-radius: 15px;
  box-shadow: 0 0 6px 2px rgb(217, 217, 217, 1);
}

.loader {
  border: 6px solid #dbf0f0;
  border-top: 6px solid @consentz-main-color;
  border-radius: 50% !important;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}