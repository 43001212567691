.custom-tooltip {
  position: relative;
  .custom-tooltip-text {
    position: absolute;
    top: 0;
    right: 45px;
    white-space: pre-line;
    font-size: 14px;
    color: #6E6E6E;
    border: 1px solid lightgrey;
    padding: 5px;
    z-index: 2;
    opacity: 1;
    background-color: white;
    width: 350px;
    text-align: center;
    line-height: 24px;
    h4 {
      font-size: 14px;
      font-weight: bold;
    }
  }
}