.dashboard-table {
    background-color: white;
    //overflow: auto;
    padding: 5px;
}
.button-show-more {
    clear: both;
    text-align: center;
    margin-top: 20px;
}
.buttons-dashbord {
    clear: both;
    margin-top: 20px;
    margin-bottom: 20px;
    .btn {
        width: 32%;
    }
}
.button-filter-open {
    text-align: right;
    padding-bottom: 5px;
    margin-right: 100px;
    margin-top: 10px;
    .button-consentz-open-filter {
        display: inline-block;
        width: 29px;
        height: 29px;
        cursor: pointer;
        background: url("../../images/charts.png") no-repeat scroll center transparent;
    }
}
.dashboard-wrapper {
    .dashboard-filter-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        overflow: hidden;
        transition: 0.5s;
        z-index: 1041;
        background-color: @filter-bg-color;
        &.opened {
            width: 420px;
        }
        .close-form-new {
            position: absolute;
            right: 0;
            top: 15px;
            padding: 5px;
            font-size: 22px;
            color: #444444;
            cursor: pointer;
        }
        .dashboard-div {
            padding: 50px 15px 20px;
            .nav-tabs {
                background: transparent;
                li {
                    width: 50%;
                    a {
                        width: 100%;
                        padding: 10px 0;
                        text-align: center;
                        background: transparent;
                        text-transform: none;
                        border: 1px solid transparent;
                        color: #555;
                        font-size: 16px;
                    }
                    &.active {
                        a {
                            border-bottom: 1px solid @consentz-main-color;
                            height: auto;
                            color: #78c2c2;
                        }
                    }
                }
            }
            .settings-checkboxes-wrapper {
                height: 400px;
                overflow-y: scroll;
            }
            .chosen-drop {
                .chosen-results {
                    max-height: 145px;
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .dashboard-div {
        padding: 10px 5px 10px;
        div {
            table {
                font-size: smaller;
            }
            .table tbody tr td, .table thead tr th {
                padding: 8px;
            }
        }
    }
}
//.title-chart {
//    display: block;
//    width: 80%;
//    float: right;
//    text-align: center;
//    font-size: 18px;
//}
//.title-chart-dashboard {
//    display: block;
//    width: 88%;
//    float: right;
//    text-align: center;
//    font-size: 18px;
//}
@media (max-width: 1024px){
    .dashboard-wrapper {
        .dashboard-filter-wrapper {
            top: -144px;
        }
    }
}
.view-graph {
    display: inline-block;
    width: 100%;
    list-style-type:none;
    div.radio {
        float:left;
        margin:0 5px;
        width: 22%;
        input {
            left: -9999px;
        }
        input:checked ~ label {
            background-color: #88cccd;
        }
        label {
            display: inline-block;
            width: 100%;
            color: #333;
            background-color: #fff;
            border: 1px solid #ccc;
            text-align: center;
            border-radius: 5px;
            padding: 9px 7px;
        }
    }
}
#connect-stripe, #reconnect-stripe {
  color: #64bdbe;
}
#connect-stripe, #reconnect-stripe:hover {
  color: #88cccd;
  text-decoration: none;
}

ul.errors-list {
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 10px;
        color: red;
    }
}

.d-flex {
    &.d-flex-center {
        align-items: center;
        justify-content: center;
    }
}
