.alert {
    border-radius: 1px;
    opacity: 0.7;
}

.alert-success {
    background-color: @success-color-bg;
    color: @success-color;
    border-color: @success-color-br;
    .close {
        color: @success-color;
        padding: 0;
    }
}

.alert-danger {
    background-color: @danger-color-bg;
    color: @danger-color;
    border-color: @danger-color-br;
    .close {
        color: @danger-color;
        padding: 0;
    }
}

.alert-warning {
    background-color: @warning-color-bg;
    color: @warning-color;
    border-color: @warning-color-br;
    .close {
        color: @warning-color;
        padding: 0;
    }
}
