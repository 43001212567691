#zmmtg-root {
  width: 50% !important;
  height: 93% !important;
  top: 10vh !important;
  left: 4vw !important;

  .meeting-app {
    width: 100% !important;

    .meeting-client {
      width: 50% !important;
      height: 93% !important;
      top: 10vh !important;
      left: 4vw !important;

      .meeting-client-inner {
        width: 50% !important;
        height: 93% !important;
        top: 10vh !important;
        left: 4vw !important;

        .join-dialog{
          width: 100% !important;
        }
      }
    }
  }
}
html, body {
  overflow: unset !important;
}
.gallery-video-container__wrap{
  width: 100% !important;
}
.gallery-video-container__canvas {
  width: 100% !important;
  margin-top: 25vh;
}
.gallery-video-container__video-frame{
  width: 100% !important;
  -webkit-transform: translate(0, -90%) !important;
  -moz-transform: translate(0, -90%) !important;
  transform: translate(0, -90%) !important;
  padding-left: 15px;
}
.full-screen-icon{
  right: 50px !important;
}
.speaker-view {
  //margin-top: 10vh;

  //.speaker-view__canvas{
  //  top: 10vh !important;
  //}
  canvas {
    //width: 100% !important;
    //height: 85%;
    zoom: 0.5;
    margin-top: 30vh;
  }
}

#wc-footer {
  position: fixed;
  bottom: 0 !important;
  width: 50% !important;
  left: 4vw !important;
}
#wc-container-left {
  height: 98% !important;
  //margin-top: 28vh !important;
}
//.meeting-info-container {
//  top: 40vh !important;
//}
//#notificationManager {
//  top: 20vh;
//}
#wc-loading {
  width: 100% !important;
}
#wc-leave{
  width: 100% !important;
}
.speaker-active-container__wrap {
  width: 50% !important;

  .speaker-active-container__video-frame{
    width: 100% !important;
    left: 0 !important;
  }
}
.gallery-video-container__main-view{
  margin-top: 0 !important;
}
//.main-layout{
//  margin-top: -93vh !important;
//}
.speaker-bar-container__video-frame{
  width: 20% !important;
  //top: 10vh !important;
  left: 40% !important;
}
#wc-container-right{
  position: absolute !important;
  height: 94% !important;

  .chat-container__chat-control{
    position: absolute !important;
    bottom: 10vh !important;

    .dropdown-menu-right{
      left: 0 !important;
    }
  }

  .chat-box__chat-textarea{
    position: absolute !important;
    bottom: 0 !important;
  }
}
.avatar-more{
  left: 80% !important;
}
.show-tip{
  left: 30% !important;
}
.video-avatar__avatar-footer span{
  margin-left: 35px !important;
}