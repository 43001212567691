.fc {
    .fc-view {
        overflow-x: scroll;
        > table {
            background-color: #fff;
        }
    }

    .fc-month-view {
        .fc-event {
            min-height: 10px;
            .calendar-event-wrapper {

                .calendar-event-show {
                    height: 100%;
                    min-height: 20px;
                    position: absolute;
                    width: 100%;
                }
                .calendar-event-content {
                    display: none;
                }
            }
        }
    }
    .fc-row.custom-fc-row {
        border-top: 1px solid #dddddd;
        padding: 10px;
        a {
            color: black;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .fc-toolbar {
        height: 0;
        h2 {
            display: inline-block;
            vertical-align: baseline;
        }
        .fc-left, .fc-right, .fc-center {
            .fc-state-default {
                background-image: none;
                font-size: 13px;
            }
            .fc-addAppointment-button {
                width: auto!important;
                margin-left: 14px;
                font-size: 18px;
                height: 29px;
                padding-left: 0.4em;
                padding-right: 0.4em;
                background-color: @btn-consentz-bg-color-light;
                color: @btn-consentz-color-light;
            }
        }
        .fc-left {
            position: absolute;
            z-index: 200;
            left: -250px;
            top: 10px;
        }
    }
}
.fc-button.fc-state-active, .fc-button.fc-state-hover {
    background-color: @consentz-main-color!important;
    color: #fff;
}
.calendar-container {
    overflow: inherit !important;
}
.fc-button.fc-month-button, .fc-button.fc-agendaWeek-button, .fc-button.fc-agendaDay-button {
    padding: 8px 22px !important;
    height: 34px !important;
}
#new_patient_form {
    z-index: 1551;
}
.mb-60 {
    margin-bottom: 60px!important;
}

.datepicker table tr td {
    &.today {
        color: inherit!important;
        background-color: inherit!important;
        border-color: inherit!important;

        &:hover {
            background-color: #eee!important;
        }
    }
    &.active {
        color: #fff;
        background-color: @consentz-main-color!important;
        border-color: @consentz-fountain-blue!important;
        &:hover {
            background-color: @consentz-main-color!important;
        }
    }
}
.datepicker table tfoot th.today {
    border: 1px #e0e0e0 solid;
}

#new_appointment_form, #new_copy_form {
    //width: 0;
    //position: absolute;
    //left: 35%;
    //top: 5px;
    //overflow: hidden;
    z-index: 1541;
    //background-color: #f5f5f5;
    //padding-bottom: 35px;

    //&.opened {
    //    width: 500px;
    //    border: 1px solid #e3e3e3;
        //transition: 0.5s;
    //}
    .copy-form-body {
        display: none;
    }
    .close-form-new {
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        font-size: 14px;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: .2;
        cursor: pointer;
        display: none;
        z-index: 9999;
    }
    &.opened {
        .close-form-new, .copy-form-body {
            display: block;
        }
        .move-copied-event, .close-copy-form {
            display: inline-block;
        }
        h4 {
            display: block;
            text-align: center;
            color: @consentz-main-color;
        }
    }
    .radio {
        padding-left: 20px;
    }
    .form-create-appointment-popup {
        .form-create-appointment {
            overflow: hidden;
            margin: 0;
            form {
                padding: 19px;
            }
            .calendar-render {
                padding: 10px 0 0 0;
                fieldset {
                    div {
                        padding-left: 0;
                        padding-right: 0;
                    }
                    .input-group {
                        width: 40%;
                        .checkbox label {
                            line-height: 20px;
                        }
                    }
                }
                .calendar-render-time {
                    padding-right: 2px;
                    padding-left: 5px;
                }
                .dropdown-menu {
                    max-width: 238px;
                    &.inner {
                        overflow-y: auto;
                    }
                }
            }
        }
    }
    h4, .move-copied-event, .close-copy-form {
        display: none;
    }
}
#new_copy_form {
    width: 0;
    position: absolute;
    left: 35%;
    top: 5px;
    background-color: #f5f5f5;
    padding-bottom: 25px;

    &.opened {
        width: 500px;
        border: 1px solid #e3e3e3;
    }
}
form[name="appointment"] {
    .radio {
        padding-left: 20px;
    }
}
.calendar-event-content {
    a {
        color: #111111 !important;
    }
}
@media (max-width: 1024px) {
    #new_appointment_form {
        top: -155px;
    }
}
.fc-month-view {
    .fc-body {
        .fc-row {
            min-height: 100px!important;
        }
    }
}
.fc-agendaWeek-view, .fc-agendaDay-view {
    .fc-event {
        min-height: 14px!important;
        border: 1px solid black!important;

        .calendar-event-wrapper {
            padding-left: 2px;
            padding-right: 2px;

            .calendar-event-show {
                display: inline-block;
                width: 5%;
            }
            .calendar-event-content {
                display: inline-block;
                width: 93%;
                a {
                    display: inline-block;
                    line-height: 16px;
                }
            }
        }
    }
}
.fc-agendaDay-view {
    .fc-event {
        margin-right: 20px;
    }
}
.fc-ltr .fc-time-grid .fc-event-container {
    margin: 0!important;
}
span.info-ajax {
    display: none;
    position: absolute;
    width: max-content;
    z-index: 1000;
    margin-top: 35px;
    padding: 4px;
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    color: #000;
    background-color: #88cccd;
    border-radius: 2px;
}

.calendar-page-view {
    display: block;
    position: relative;
    width: 100%;
    padding-left: 270px;
    .calendar-filter {
        display: block;
        width: 250px;
        position: absolute;
        top: 30px;
        left: 0;
    }
    #calendar-hold-area {
        display: block;
        //width: 250px;
        min-height: 100px;
        left: 0;
        top: 500px;
        border: 1px solid lightgray;
        border-radius: 4px;
        padding: 5px;
        p {
            text-align: center;
            padding-bottom: 5px;
            border-bottom: 1px solid lightgray;
        }
    }
    .external-event {
        display: inline-block;
        width: 170px;
        min-height: 14px;
        margin-top: 5px;
        margin-right: 5px;
        vertical-align: top;
        cursor: move;
        color: black;
        border-color: black;
        background-color: @consentz-main-color;
        &:hover {
            color: black;
        }
        span {
            display: inline-block;
            white-space: pre-line;
        }
    }
    #appointment_endType {
        .radio {
            input {
                margin-left: 0!important;
            }
        }
    }
    //form {
    //    .filter-date > div {
    //        padding-left: 4px;
    //    }
    //}
}
.xdsoft_datetimepicker .xdsoft_datepicker {
    width: 231px;
    margin-left: 8px;
}
.fc-day-header, .fc-axis.fc-time {
    text-transform: capitalize !important;
}
.fc-resource-cell {
    text-transform: capitalize !important;
}
.fc td.fc-widget-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.page-calendar {
    .select2-container--open {
        z-index: 1701;
    }
}

.modal .btn-apt-form {
    width: 120px;
    font-size: 12px;
    padding: 6px 1px;
}
