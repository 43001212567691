.sk-three-bounce {
  margin: 40px auto;
  width: 80px;
  text-align: center; }
.sk-three-bounce .sk-child {
  width: 20px;
  height: 20px;
  background-color: @btn-consentz-bg-color-light;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both; }
.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }
.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }
@-webkit-keyframes sk-three-bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
@keyframes sk-three-bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }


.spinner {
  margin: 70px auto 0;
  width: 150px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: @btn-consentz-bg-color-light;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.40s;
  animation-delay: -0.40s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.30s;
  animation-delay: -0.30s;
}

.spinner .bounce3 {
  -webkit-animation-delay: -0.20s;
  animation-delay: -0.20s;
}

.spinner .bounce4 {
  -webkit-animation-delay: -0.10s;
  animation-delay: -0.10s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}