.goods-received-item {
  .form-group{
    [id^='goodsReceived'] {
      display: table-row;
      .form-group {
        .checkbox {
          margin-top: 0;
          margin-bottom: 0;
          label {
            margin-bottom: -10px;
          }
        }
        display: table-cell;
        padding: 5px;
      }
    }
  }
}
.new-item-goods {
  display: inline-block;
  margin-left: 15px;
}
.bg-td {
  width: 28%;
  display: inline-block;
}
.sm-td {
  width: 12%;
  display: inline-block;
}
.status-td {
  width: 4%;
  display: inline-block;
}
.bg-th {
  width: 29%;
  display: inline-block;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}
.bg-th:first-child {
  margin-left: 52px;
}
.sm-th {
  width: 11%;
  display: inline-block;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}
.status-th {
  width: 4%;
  display: inline-block;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.remove-item {
  float: left;
  margin-top: 6px;
  padding-top: 9px;
  span {
    color: red;
  }
}
.parent-completed {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  a.remove-item {
    visibility: hidden;
  }
}
