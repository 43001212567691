.conversations-indicator-wrapper {
    float: right;
    margin-right: 26px;
    //clear: both;
    //margin-left: 40px;
    //padding-top: 22px;
    //position: absolute;
    //right: 30px;
    //top: 50px;

    .conversations-open {
        width: 27px;
        height: 23px;
        background: url("../../images/icon_chat.png") no-repeat scroll center transparent;
        position: relative;
        cursor: pointer;
        &.unread-all:after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            position: absolute;
            background-color: @consentz-main-color;
            right: -7px;
            bottom: -7px;
        }
    }
}
.conversations-wrapper {
    position: absolute;
    right: 0;
    width: 0;
    max-width: 550px;
    height: 80vh;
    top: 100px;
    z-index: 1040;
    overflow: hidden;
    transition: 1s;
    font-size: 14px;
    &.open {
        width: 80vw;
    }
    .conversations-close {
        position: absolute;
        right: 10px;
        top: 20px;
        font-size: 20px;
        cursor: pointer;
        color: @consentz-main-color-darker;
    }
    .conversations-refresh {
        position: absolute;
        right: 60px;
        top: 20px;
        font-size: 20px;
        cursor: pointer;
        color: @consentz-main-color-darker;
    }
    .conversations-list-wrapper {
        width: 200px;
        float: left;
        background-color: #f3f3f3!important;
        height: 100%;
        border: 2px solid #dfdfdf;
        overflow: hidden;
        .conversations-list-title {
            padding: 20px 5px 15px;
            border-bottom: 1px solid #dfdfdf;
            background-color: @consentz-white;
            .create-conversation-wrapper {
                padding-right: 5px;
                margin-top: -6px;
                float: right;
            }
        }
        .conversations-list {
            height: 100%;
            padding-top: 60px;
            margin-top: -60px;
            .conversations-list-inner {
                overflow-y: auto;
                height: 100%;
                background-color: @consentz-white;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li.conversations-list-item {
                background-color: @consentz-white;
                padding: 15px 10px 15px 5px;
                border: none!important;
                border-bottom: 1px solid #dfdfdf!important;
                border-radius: 0!important;
                cursor: pointer;
                &.active {
                    background-color: @consentz-main-color;
                }
                &.unread {
                    .conversation-name:after {
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 4px;
                        background-color: @consentz-main-color;
                    }
                }
                .conversation-delete {
                    float: right;
                    .glyphicon {
                        color: @consentz-main-color-darker;
                    }
                }
                .conversation-name {
                    margin-right: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-right: 10px;
                }
            }
        }
    }
    .conversations-chat-wrapper {
        margin-left: 200px;
        height: 100%;
        background-color: #eaeaea;
        border: 2px solid #dfdfdf;
        border-left: none;
        border-bottom: none;
        .conversations-chat-title {
            background-color: @consentz-white;
            padding: 20px 5px 15px 10px;
            border-bottom: 1px solid #dfdfdf;
        }
        .conversations-send-message-wrapper {
            height: 60px;
            background-color: @consentz-white;
            form {
                padding: 0 10px;
            }
        }
        .conversations-messages-wrapper {
            height: 100%;
            padding-top: 60px;
            padding-bottom: 60px;
            margin-top: -60px;
            margin-bottom: -60px;
            .conversations-messages-holder {
                position: relative;
                width: 100%;
                height: 100%;
                .conversations-messages-loader {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: #ffffff;
                    z-index: 2;
                    display: none;
                    img {
                        width: 100px;
                        height: auto;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-top: -50px;
                        margin-left: -50px;
                    }
                }
                .conversations-messages-inner {
                    height: 100%;
                    overflow-y: auto;
                    position: relative;
                    background-color: @consentz-white;

                    .conversations-messages-item {
                        display: none;
                        &.active {
                            display: block;
                        }

                        ul.conversations-messages-list {
                            list-style-type: none;
                            margin: 0;
                            padding: 0;
                            li {
                                background-color: #EAEAEA!important;
                                margin: 10px 80px 10px 20px;
                                border-radius: 4px!important;
                                border: none!important;
                                float: left;
                                clear: both;
                                .message-item-text {
                                    padding: 15px;
                                    background-color: #ffffff!important;
                                }
                                &.mine {
                                    margin-left: 80px;
                                    margin-right: 20px;
                                    float: right;
                                    .message-item-text {
                                        background-color: #f5f5f5!important;
                                    }

                                }

                            }
                        }
                    }
                }
            }

        }
    }
}
#popupCreateConversation {
    ul {
        li {
            border: 0!important;
            border-radius: 0!important;
            a {
                font-size: 14px!important;
                display: block;
                padding: 3px 20px!important;
                clear: both;
                font-weight: 400;
                line-height: 1.42857143;
                color: #333!important;
                white-space: nowrap;
            }
            &.disabled {
                a {
                    color: #777!important;
                }
            }
        }
    }
}