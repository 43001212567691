
#global-spinner-wrap {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 120%;
    z-index: 9999;
    div {
        margin-top: 15%;
    }
}