.print-clinic-info {
    display: none;
    float: right;
    p,h1,h2,h3,h4,h5 {
        padding: 0;
        margin: 0;
        text-align: right;
    }
    h1,h2,h3,h4,h5 {
        margin-top: 10px;
    }
}
.print-clinic-info-footer {
    display: none;
    width: 100%;
    border-top: 1px solid black;
    margin-top: 8%;
    padding-top: 20px;
    text-align: center;
    //position: absolute;
    //bottom: -20pc;
}
.print-clinic-info-pre-footer {
    display: none;
    width: 100%;
    margin-top: 10%;
    padding-left: 50%;
    text-align: left;
    p {
        padding: 0;
        margin: 0;
    }
}
.print-patient-info {
    display: none;
    float: left;
    padding-left: 8px;
    p,h1,h2,h3,h4,h5 {
        padding: 0;
        margin: 0;
        text-align: left;
    }
    h1,h2,h3,h4,h5 {
        margin-top: 10px;
        margin-bottom: 100px;
    }
}
.print-clinic-logo {
    padding: 0;
    margin: 0;
    width: 90px;
    display: none;
    float: right;
    margin-bottom: 10px;
}
.print-body {
    display: none;
    clear: both;
    padding-top: 10px;
    .table-print {
        width:100%;
        margin-top: 20px;
    }
    .table-row {
        width:100%;
        clear:both;
        padding: 0 5px;
        height: 34px;
        &:first-child {
            .table-col {
                background-color: #e5e5e5!important;
                -webkit-print-color-adjust: exact;
                font-weight: bold;
            }
        }
        .invoice-due {
            padding-top: 5px;
            padding-bottom: 5px;
            border-top: 2px solid black;
            border-bottom: 2px solid black;
            margin-top: 10px;
        }
        .title-total {
            margin-left: 56%;
        }
        .table-col {
            float:left;
            width:14%;
            height: 34px;
            min-width: 14%;
            &:first-child {
                width: 30%;
            }
        }
        .payment-type {
            margin-left: 26%;
            padding-left: 5px;
        }
        .voucher-total {
            margin-left: 42%;
        }
        .payments-div-title {
            width: 30%!important;
        }
    }
    .pdf-invoice-note {
        margin-top: 35px;
        border: 1px solid dimgrey;
        padding-top: 10px;
        padding-left: 15px;

        p{
            padding-top: 10px;
        }
    }
}
.print-show {
    display: none;
}
.print-barcode {
    display: none;
}
@media print {
    .print-clinic-info, .print-clinic-logo, .print-show, .print-patient-info,
    .print-clinic-info-footer, .print-clinic-info-pre-footer, .print-body, .print-barcode {
        display: block!important;
    }
    table.table {
        //font-size: 10px;
        //.print-collsp {
        //    width: 25%!important;
        //}
        //.table-actions {
        //    width: 2px!important;
        //}
        display: none!important;
    }
    button, form, header, .top-wrapper, .print-link,
    .history-invoice, .patient-logo-name, .flash-block, .patient-logo-name,
    #print_inv, .not-print, #print_correspondence {
        display: none !important;
    }

    //form[name='invoice_edit_note'] {
    //    display: block!important;
    //    .col-sm-6 {
    //        padding-left: 0;
    //        width: 100%;
    //    }
    //    .col-sm-6:first-of-type {
    //        display: none!important;
    //    }
    //}

}