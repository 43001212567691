.prepay-list {
  background-color: white;
  margin-top: 20px;
  a {
    color: @consentz-main-color-darker;
  }
  table {
    border: none;
    thead, tbody, tr, td, th {
      border: none;
    }
    margin-bottom: 10px;
  }
  .prepay-list-header {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    min-height: 28px;
    border-bottom: 1px solid #ECECEC;
  }
  .prepay-list-body {
    width: 100%;
  }
  .prepay-list-footer {
    width: 100%;
  }
  .prepay-list-invoices {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: none;
  }
  .title {
    margin-right: 10px;
    font-weight: bold;
  }
  .total {
    font-weight: bold;
    color: black;
  }
}