.navbar {
    border-radius: 0;
    margin-bottom: 0;
    min-height: 0;
    //margin-top: -20px;
}
.mock-block {
    display: block;
    width: 100%;
    min-height: 40px;
}
.container.organisation-div {
    padding-bottom: 7px;
    position: relative;
    padding-top: 5px;
}
.navbar-default {
    .navbar-text {
        color: @navbar-default-color;
    }
    .navbar-nav {
        @media (max-width: @screen-xs-max) {
            margin: 10px 0;
        }
        li {
            a {
                color: @navbar-default-color;
                font-size: 16px;
                .role-user {
                    font-size: 14px;
                }

                &:hover, &:focus {
                    color: @navbar-default-color;
                    background-color: @wrapper-bg;
                    text-decoration: underline;
                }
            }
        }
        >li {
            >a {
                @media screen and (min-width: 768px) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
            }
        }
        .active {
            a {
                font-weight: 500;
                color: @navbar-default-color;
                background-color: transparent;
                text-decoration: underline;

                &:hover, &:focus {
                    color: @navbar-default-color;
                    background-color: @wrapper-bg;
                }
            }
        }
    }
}
ul {
    @media screen and (max-width: 767px) {
        &.navbar-nav {
            &:first-of-type {
                margin-bottom: 0;
            }
            &:last-of-type {
                margin-top: 0;
            }
        }
    }
}
.navbar>.container-fluid .navbar-brand {
    height: auto;
    padding: 8px;
    margin-left: 10px;
    position: absolute;

    img {
        width: auto;
        height: 60px;
        display: block;
        //position: relative;
        //bottom: -25px;
    }
}
@media (max-width: 1387px) {
    .navbar>.container-fluid .navbar-brand {
        height: auto;
        padding: 8px;
        margin-left: 10px;
        position: relative;

        img {
            width: auto;
            height: 60px;
            display: block;
            //position: relative;
            //bottom: 0;
        }
    }
    .container.organisation-div {
        padding-top: 30px;
        padding-bottom: 0;
    }
}
.navbar-toggle {
    margin: 23px 10px;
    padding: 6px 9px;
    border: 1px solid transparent;
    border-radius: 0;
}

.top-wrapper {
    background-color: #fff;
    .top-menu {
        border-bottom: 1px solid @consentz-main-color;
        .container {
            padding: 0;
            ul {
                position: relative;
                li {
                    border: 1px solid @consentz-main-color;
                    border-left: none;
                    border-bottom: none;
                    background-color: #fff;
                    &:first-child {
                        border-left: 1px solid @consentz-main-color;
                        border-top-left-radius: 5px;
                    }
                    &:last-child {
                        border-top-right-radius: 5px;
                    }
                    a {
                        padding: 6px 10px;
                        color: @consentz-main-color;
                        font-size: 16px;
                        &:hover, &:focus, &:active {
                            background-color: transparent;
                        }
                    }
                    &.active, &:hover {
                        background-color: @consentz-main-color;
                        a {
                            color: #fff;
                        }
                    }
                    &.active {
                        a {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    .bottom-menu {
        background-color: @consentz-main-color;
        //min-height: 40px;
        .container {
            padding: 0;
            ul {
                li {
                    float: left;
                    a {
                        padding: 5px 8px;
                        color: #fff;
                        font-size: 14px;
                        &:hover, &:focus, &:active {
                            background-color: transparent;
                        }
                    }
                    &.active {
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            border: 6px solid transparent;
                            border-bottom-color: @container-bg-color;
                            margin-left: -3px;
                        }
                        a {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {
    .navbar-default .navbar-nav>li>a {
        padding-top: 6px;
        padding-bottom: 0;
    }
}

.site-menubar-footer .site-menu-icon {
    margin-right: 0;
}
