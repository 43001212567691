.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
label[for_inputfile] {
  font-size: 14px;
  font-weight: 400;
  width: 170px;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  color: white;
  background-color: @btn-consentz-bg-color-light;
  display: inline-block;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}
