.breadcrumb {
    padding: 0;
    li {
        font-size: 14px;
        a {
            color: @consentz-fountain-blue;
            background-color: transparent;
            font-weight: 400;
        }
        a:hover{
            color: @btn-consentz-color-dark-blue;
            text-decoration: underline;
        }
        &+li {
            &:before {
                padding: 0 30px;
                color: @breadcrumb-color;
                position: relative;
                top: 1px;
                display: inline-block;
                font-family: 'Glyphicons Halflings';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e080";
            }
        }
        &:last-child {
            a {
                color: @breadcrumb-color;
            }
        }
    }
    background-color: transparent;
}

.breadcrumbs-block {
    margin: 20px;
}

@media screen and (max-width: 767px) {
    .breadcrumbs-block {
        margin: 15px;
        .breadcrumb {
            li {
                &+li {
                    &:before {
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
}
