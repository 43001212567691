@import "modules/_variables";
@import "../../bower_components/bootstrap/less/bootstrap";
@import "modules/_select2sortable";
@import "modules/_login";
@import "modules/_glyphicon";
@import "modules/_custom_reports";
@import "modules/_typography";
@import "modules/_header";
@import "modules/_menu";
@import "modules/_table";
@import "modules/_alert";
@import "modules/_panel";
@import "modules/_pagination";
@import "modules/_filter";
@import "modules/_calendar";
@import "mixins/buttons";
@import "modules/_button";
@import "modules/_breadcrumbs";
@import "modules/_page_title";
@import "modules/_flash";
@import "modules/_tabbed_submenu";
@import "modules/_form";
@import "modules/_filters_links_header";
@import "modules/_tabulated_content";
@import "modules/_sidebar_menu";
@import "modules/_dashboard";
@import "modules/_highcharts";
@import "modules/_report";
@import "modules/_onboarding";
@import "modules/_inputfile";
@import "modules/_toggle";
@import "modules/_audio";
@import "modules/_chat";
@import "modules/_goods_received_item";
@import "modules/_tooltip";
@import "modules/_print";
@import "modules/_reminders";
@import "modules/_spin";
@import "modules/_stripe";
@import "modules/_globalspinner";
@import "modules/_prepay_list";
@import "modules/_responsive_helper";
@import "modules/_mixins";
@import "modules/_task-board";
@import "modules/_paymentsense";
@import "modules/_patient_pdf";
@import "modules/_patient_treatment_plan_entry";
@import "modules/_select2";
@import "modules/_modal";
@import "modules/_preloader";
@import "modules/_zoom";

html, body, .wrapper {
    width: 100%;
    height: 100%;
}

body {
    background-color: @wrapper-bg;
    font-family: 'Roboto', sans-serif;

    .bg-consentz-main {
        background-color: @consentz-main-color !important;
        .nav-link {
            color: @navbar-default-bg !important;
            font-weight: bold;
        }
    }
    .page {
        .page-header {
            padding: 10px 30px;
            margin: 0;
            border-bottom: none;
        }
        background: #fafafa;
    }
}

body, .glyphicon {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
}
.align-center {
    text-align: center;
}
.clear {
    clear: both;
}
.clear-margin-right {
    margin-right: 0;
    padding-right: 0;
}
main {
    background-color: @container-bg-color;
    margin-bottom: 20px;
    position: relative;
    &.container {
        padding-left: 50px;
        padding-right: 50px;
    }
    &.container.iframe-page {
        padding-left: 0;
        padding-right:0;
        div {
            padding-left: 0;
            padding-right: 0;

            iframe {
                width: 100%;
                margin: 0 auto;
            }
        }
    }
}
.full-height {
    min-height: ~"calc(100% - 82px)";
}

//.container {
    //padding-bottom: 20px;
//}

.page-header {
    z-index: 1030 !important;
}

.login-links {
    padding-top: 50px;
    a {
        color: @btn-consentz-bg-color-light;
    }
}
.consentz-checkbox {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: url(../../images/checkbox.png) center no-repeat;
    &.active {
        background-image: url(../../images/checkbox_checked_new.png);
    }

}

.datetime {
    padding-right: 15px !important;
    &:after {
        content: "\e109";
    }
}
.tab-content {
    padding-top: 0;
}
.vertical-align {
    display: flex;
    align-items: center;
}
.vertical-align-block {
    display: flex;
    align-items: center;
    padding: 5px 0;
}
.media-patient-image {
    display: flex;
    align-items: center;
    .media-heading {
        margin: 0 10px;
    }
}
.bottom-align:first-child {
    margin-top: 35px;
}
.bottom-align {
    margin-bottom: 7px;
}
.bottom-align-opt {
    margin-top: 15px;
}
.typeahead__container {
    margin-bottom: 10px;
    .typeahead__list {
        z-index: 1800;
    }
    .typeahead__field {
        input {
            border-radius: 4px;
            height: 34px;
            width: 100%;
            &.after-choose {
                padding-left: 34px;
            }
        }
        .appoitment-input {
            border-radius: 4px 0 0 4px;
            height: 34px;
            &.after-choose {
                padding-left: 34px;
            }
        }
    }
    button {
        font-size: small;
        height: 34px;
        background-color: @consentz-main-color;
        .button-variant(@btn-consentz-color-light; @btn-consentz-bg-color-light; @btn-consentz-border-light);
        &:hover {
            .button-variant(@btn-consentz-color-dark; @btn-consentz-bg-color-dark; @btn-consentz-border-dark);
        }
    }
}

.search {
    min-width: 300px;
    @media (max-width: @screen-xs-max) {
        min-width: 100px;
    }
}

form {
    &[name="clinic"] {
        padding: 20px 0;
    }
}
.clinic-thumbnail {
    background: transparent;
    border: none;

    img {
        width: 50px;
    }

}

// ----------------- page clinics

.table-clinics {
    margin-top: 30px;
    margin-bottom: 100px;
}




.span-search {
    display: block;
    width: 100%;
    padding-left: 11%;
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: medium;
}

.input-search {
    width: 50%;
    margin-left: 15%;
}
.link-export {
    display: inline-block;
    margin-left: 50px;
}
.waiting-search-practitioner {
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.select-boots-margin {
    margin-bottom: 10px;
}
.date_form {
    width: 29.5%;
}
.header-clinics {
    clear: both;
}
.media .avatar-lg {
    width: 60px;
}
.menu-clinics {
    margin-bottom: 40px;
    padding: 40px 55px;
    background-color: #EBEBEB;
    display: inline-block;
    width: 100%;
}
.div-inline-block {
    display: inline-block;
}
.img-profile {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    border: 2px solid @consentz-main-color;
}
.img-product-goods-received {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    border: 1px solid @consentz-main-color;
}
a {
    &.shared, &.sharedOwner {
        position: relative;
        //display: block;
    }
    &.shared:after {
        content: '';
        display: block;
        width: 23px;
        height: 23px;
        background: url("../images/icon_share_patients_profile@3x.png") no-repeat scroll left center transparent;
        background-size: 23px;
        position: absolute;
        top: 107px;
        left: 100px;
    }
    &.sharedOwner:after {
        content: '';
        display: block;
        width: 23px;
        height: 23px;
        background: url("../images/icon_share_patients_profile_ff7d00@3x.png") no-repeat scroll left center transparent;
        background-size: 23px;
        position: absolute;
        top: 107px;
        left: 100px;
    }
}
td {
    &.shared, &.sharedOwner {
        position: relative;
    }
    &.shared:after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background: url("../images/icon_share_patients_profile@3x.png") no-repeat scroll left center transparent;
        background-size: 18px;
        position: absolute;
        top: 45px;
        left: 65px;
    }
    &.sharedOwner:after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background: url("../images/icon_share_patients_profile_ff7d00@3x.png") no-repeat scroll left center transparent;
        background-size: 18px;
        position: absolute;
        top: 45px;
        left: 65px;
    }
}
.chosen-container .chosen-results li.highlighted {
    background-color: @consentz-main-color;
}

select {
    &.chosen-select {
        .loader;
        height: 10px;
        width: 10px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        overflow: hidden;
        padding: 5px;

        option {
            display: none;
        }

        &.document-ready {
            height: 34px;
            width: auto !important;
            padding: 6px 12px;
            animation: none;
        }
    }
}

.img-edit {
    width: 200px;
}
.branding-item-wrapper-outer {
    .branding-item-success-message {
        display: none;
        text-align: center;
        margin-bottom: 10px;
        color: @consentz-main-color;
    }
    &.active {
        .branding-item-success-message {
            display: block;
        }
    }
}
.branding-item-wrapper {
    display: inline-block;
    position: relative;
    margin-bottom: 15px;
    .form-group {
        margin: 0;
        label {
            margin-bottom: 0;
            line-height: 20px;
        }
        img {
            display: block;
        }
    }
    .buttons-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 20px;
        bottom: 0;
        background-color: rgba(0,0,0,0.7);
        display: none;
        .delete-button {
            position: absolute;
            top: 5px;
            right: 5px;
            padding: 2px;
            button {
                color: #fff;
            }
        }
        .edit-button {
            position: absolute;
            top: 5px;
            right: 35px;
            padding: 2px;
            cursor: pointer;
            span {
                color: #fff;
            }
        }
    }
    &:hover {
        .buttons-wrapper {
            display: block;
        }
    }

}

.tr-profile > td {
    vertical-align: middle !important;
}

.img-load, .img-load + label {
    float: right;
    margin-top: 50px;
}

//--------------------help page---------
.btn-add-media {
    border-radius: 0;
}
.help-pdf-div {
    display: inline-block;
    width: 100%;
    position: relative;
}
.help-pdf-div .help-pdf {
    font-size: 1.5em;
}
.help-pdf-div .btn-edit {
    position: absolute;
    top: 9px;
    left: 30%;
}
.help-pdf-div button {
    position: absolute;
    top: 10px;
    left: 35%;
}
.help-page {
    position: relative;
    display: block;
    width: 33%;
    float: left;
    margin: 0 auto;
}

.help-page figcaption {
    margin-top: -10px;
    padding: 0 10px;
}

.btn-link {
    color: black;
}

.video-div {
    position: relative;
}

.video-form .btn-edit {
    position: absolute;
    top: 50px;
    left: 610px;
}

.video-form button.glyphicon-trash {
    position: absolute;
    top: 50px;
    left: 635px;
}

.img-div {
    clear: both;
    width: 100%;
}

.iframe-div {
    position: relative;
    display: block;
    width: 33.3333%;
    float: left;
    margin-top: 20px;
}

.iframe-div .btn-edit {
    position: absolute;
    top: 20px;
    left: 175px;
}

.iframe-div button.trash {
    position: absolute;
    top: 23px;
    left: 200px;
}

.help-page .btn-edit {
    position: absolute;
    top: 20px;
    left: 170px;
}

.help-page button.glyphicon-trash {
    position: absolute;
    top: 19px;
    left: 185px;
}

.text-add-file {
    margin-left: 10px;
}

.div-add-button {
    margin: 40px 0;
}

.video-div {
    width: 100%;
}

.text-screenshots {
    margin-bottom: 10px;
    padding-top: 50px;
}

.img-edit-help {
    height: 150px;
    width: 97%;
    float: none;
    margin-right: 0;
}

//---------------------Forms Area---------
.search-form-formsArea {
    padding-top: 40px;
    padding-bottom: 40px;
}

.btn-upload {
    background-color: #A3A3A3;
}

.upload {
    position: relative;
}

.upload input[type="file"] {
    opacity: 0;
}

.upload .text-upload {
    position: absolute;
    width: 100%;
    padding: 6px 10px;
    border: 1px solid #CCCCCC;
}

.upload .text-upload p {
    margin: 0;
    color: #999999;
}

.btn-status {
    background-color: #EBEBEB;
}

//-----------------------Equipment and Room----------
.div-add-equipment {
    margin-bottom: 40px;
}

//-----------------------Inventory-------------------
.form-inventory-name {
    width: 40% !important;
    display: block;
}

.form-inventory-stock {
    width: 85%;
    margin-left: 10px;
    margin-right: 10px;
}

.form-inventory-div-name {
    border-bottom: 1px solid black;
    width: 100%;
    display: inline-block;
}

.form-inventory-div-name > div {
    float: left;
    margin-left: 20px;
}

//-------------------------Marketing--------------------
.big-checkbox {
    width: 19px;
    height: 19px;
}

.marketing-block {
    clear: both;
    width: 100%;
    display: inline-block;
    margin-bottom: 30px;
}

.img-clinic-media {
    width: 100%;
}

.delete-button {
    cursor: pointer;
}

.content-div {
    clear: both;
    width: 100%;
}

.marketing-img-wrapper {
    .marketing-img {
        position: relative;
        display: block;
        width: 25%;
        //height: 150px;
        float: left;
        margin-top: 20px;
        padding: 0 10px;
        &:nth-child(4n+1) {
            clear: both;
        }
    }
}

.form-active-img {
    position: absolute;
    top: 2px;
    left: 205px;
}

.marketing-img .delete-button {
    position: absolute;
    top: 13px;
    left: 233px;
}

.marketing-video-wrapper{
    .marketing-video {
        position: relative;
        display: block;
        width: 25%;
        float: left;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 10px;
        &:nth-child(4n+1) {
            clear: both;
        }
    }
}

.form-active-video {
    position: absolute;
    top: 2px;
    left: 200px;
}

.marketing-video .delete-button {
    position: absolute;
    top: 13px;
    left: 230px;
}

.marketing-pdf {
    position: relative;
    display: block;
    width: 100%;
    float: left;
    margin-top: 10px;
}

.marketing-pdf p {
    display: inline-block;
    width: 80%;
}

.form-active-pdf {
    position: absolute;
    top: -5px;
    left: 45%;
}

.marketing-pdf .delete-button {
    position: absolute;
    top: 0;
    left: 50%;
}

//---------------------Patients-------------
.btn-tabs-patient {
    width: 16.66666%;
}

.btn-tabs-patient a {
    text-align: center;
    font-size: medium;
    background-color: #EBEBEB;
}

.patient-name {
    display: inline-block;
    width: 80%;
    margin-left: 30px;
    font-size: large;
}

.custom-upload {
    display: block;
    width: 160px;
    height: 160px;
    margin-left: 20px;
    border: 1px dashed black;
    float: left;
}
.branding-item-wrapper {
    .custom-upload {
        margin: 0 auto;
    }
}

.custom-upload a {
    display: block;
    width: 100%;
    height: 100%;
}

.custom-upload span {
    text-align: center;
    margin-top: 30%;
    margin-left: 40%;
    font-size: 2.5em;
    color: black;
    cursor: pointer;
}

.custom-upload p {
    text-align: center;
    margin-top: 15px;
    color: black;
    cursor: pointer;
    text-decoration: none;
}

.div-patient {
    float: left;
    margin-left: 20px;
    margin-bottom: 20px;
    //width: 160px;
}

.img-patient {
    width: auto;
    height: 160px;
}

.description-patient {
    //display: inline-block;
    width: 100%;
    min-height: 20px;
    text-align: center;
    margin-top: 10px;
    padding: 0 5px;

    dl {
        dd {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            //align-items: stretch;
            form {
                display: none;
            }
            &:hover {
                form {
                    display: inline-flex;
                }
            }

        }
    }
}

.save-patient {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 50px;
}

.figure-patient {
    position: relative;
    text-align: center;
    background-color: white;
}

.active-patient {
    position: absolute;
    top: 115px;
    left: 10px;
}

.figure-patient .add-button {
    position: absolute;
    top: 10px;
    left: 80px;
}

.figure-patient .edit-button {
    position: absolute;
    top: 10px;
    right: 35px;
}

.figure-patient .delete-button {
    position: absolute;
    top: 8px;
    right: 3px;
}

.div-patient-plan {
    display: block;
    width: 100%;
    padding: 20px 40px;
    margin: 45px 0 0 0;
    background-color: #EEEEEE;
}

.div-patient-plan a {
    margin-top: 35px;
}

.div-patient-form {
    display: block;
    height: 110px;
    width: 100%;
    padding: 42px 40px;
    margin: 30px 0 0 0;
    background-color: #EEEEEE;
}

.div-patient-form p {
    display: inline-block;
}

.div-patient-form a {
    margin: 0 5px;
    color: black;
}

.div-patient-form .open-file {
    margin-left: 20px;
    color: black;
}

.plan-content {
    display: inline-block;
}

//---------------------- Lead-capture-----------------

.none-padding-left {
    padding-left: 0!important;
}
.none-padding-right {
    padding-right: 0!important;
}
.lead-capture-name-patient {
    font-size: 16px;
    display: block;
    width: 100%;
    padding-left: 30px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}
.print-link {
    display: inline-block;
    font-size: 28px;
    color: @consentz-main-color;
}
.pdf-link {
    display: inline-block;
    font-size: 28px;
    color: @consentz-main-color;
}
.glyphicon-pencil, .glyphicon-trash {
    color: @consentz-main-color;
    font-size: 16px;
}
#print_inv {
    margin-bottom: -20px;
}
.download-link {
    display: inline-block;
    width: 32px;
    margin-top: -21px;
    min-height: 32px;
    background: url("../images/download-icon_copy.png") no-repeat scroll top center transparent;
    background-size: 32px 32px;
}
.archive-link {
    display: inline-block;
    color: @consentz-main-color;
    font-size: 28px;
}
.archive-link-2 {
    display: inline-block;
    width: 32px;
    line-height: 32px;
    min-height: 32px;
    vertical-align: middle;
    background: url("../images/icon_archive_2.png") no-repeat scroll left center transparent;
}
.back-link {
    display: inline-block;
    width: 32px;
    line-height: 32px;
    min-height: 32px;
    vertical-align: middle;
    background: url("../images/icon_left_new.png") no-repeat scroll left center transparent;
}
.edit-link-1 {
    display: inline-block;
    width: 32px;
    line-height: 32px;
    min-height: 32px;
    background: url("../images/icon_edit.png") no-repeat scroll left center transparent;
}
.stock-asjust {
    display: inline-block;
    width: 32px;
    line-height: 32px;
    min-height: 32px;
    background: url("../images/Clinic/stock_adjust.png") no-repeat scroll left center transparent;
}
.stock-history {
    display: inline-block;
    font-size: 28px;
}
.stock-info {
    display: inline-block;
    width: 40px;
    line-height: 40px;
    min-height: 40px;
    background: url("../images/Clinic/stock_info.png") no-repeat scroll left center transparent;
}
.trash-link {
    display: inline-block;
    width: 32px;
    line-height: 32px;
    min-height: 32px;
    background: url("../images/icon_trash.png") no-repeat scroll left center transparent;
    background-size: 14px;
}
.trash-link-reminder {
    display: inline-block;
    width: 18px;
    line-height: 22px;
    min-height: 22px;
    background: url("../images/icon_trash.png") no-repeat scroll left center transparent;
}
//============--------------- NOTES -------------------
.notes {
    dl {
        margin: 0;
        padding: 13px 20px;
        border-top: 1px solid @tbl-border-default-color;
        &:first-child {
            border-top: none;
        }
    }

    dt, .title-note {
        a, .trash {
            color: @btn-consentz-bg-color-light;
            font-size: 20px;
            &:hover, span:hover {
                text-decoration: none;
                color: @btn-default-hover;
            }
        }
    }
    .note-header {
        line-height: 20px;
        font-size: 14px;
        padding: 5px 15px;

        .title {
            font-size: 16px;
            a, button.glyphicon {
                font-size: 16px;
                padding: 0 10px;
                text-decoration: none;
                color: @btn-consentz-bg-color-light;
                &:hover {
                    text-decoration: none;
                    color: @btn-consentz-bg-color-dark;
                }
            }
        }
    }
    .note-footer {
        padding: 5px 15px;
        span {
            &:last-child {
                float: right;
                form {
                    padding-right: 30px;
                }
            }
        }
        //text-align: right;
    }
    .title-note {
        display: flex;
        align-items: stretch;
        flex-direction: row;
        justify-content: space-between;
        .h4 {
            display: flex;
            align-items: stretch;
            flex-direction: row;
            justify-content: space-between;
            form {
                padding-left: 10px;
            }
        }
    }
    dd {
        margin-top: 10px;
        padding: 10px 20px;
        border: 1px solid @tbl-border-default-color;
        border-radius: 2px;

        .note-body {
            padding-bottom: 10px;
        }
        footer {
            display: flex;
            align-items: stretch;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.prepay-label {
    line-height: 32px;
    font-weight: bold;
}
.color-header {
    color: @consentz-main-color;
}
.colorpicker {
    min-width:125px !important;
}

.d-flex {
    display: flex;
}

.d-none {
    display: none;
}

.position-rel {
    position: relative;
}

.align-items-center {
    align-items: center;
    justify-content: center;
}

.full-height-hack {
    @media @sm {
        height: calc(~"100vh - 82px");
    }
}

.hxc-login,
.hxc-search,
.hxc-page-search {
    padding: 30px 0;
}

.hxc-login {
    h1 {
        font-size: 24px;
        text-align: center;
        margin-top: 0;
        color: @consentz-main-color;
    }
}

.hxc-login,
.hxc-search {
    background: white;

    @media @sm {
        min-width: 460px;
        padding: 30px;
        .round-borders-shadow;
    }

    .hxc-logo {
        padding: 20px 0 30px;

        img {
            min-width: 180px;
        }
    }

    .hxc-buttons {
        .btn-form {
            display: block;
            width: 100%;


            @media @sm {
                display: inline-block;
                width: 145px;
                margin-bottom: 0;
            }
        }

        text-align: right;
    }
}

.hxc-search {
    #search_item {
        padding: 6px 12px 6px 40px;
        font-size: 16px;
    }

    .glyphicon-search {
        position: absolute;
        top: 6px;
        left: 10px;
        font-size: 22px;
        color: #b3b3b3;
    }
}

.hxc-page-search {
    .d-flex {
        flex-direction: column;

        @media @sm {
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 30px;
        }
    }

    .search-form {
        @media @sm {
            min-width: 240px;
            margin-right: 50px;
        }
    }

    #search_item,
    .hxc-btn-search,
    .logout {
        margin-bottom: 15px;

        @media @sm {
            margin: 0;
        }
    }

    .hxc-btn-search {
        width: 100%;
        .button-variant(@btn-consentz-color-light; @btn-consentz-bg-color-light; @btn-consentz-border-light);
        &:hover {
            .button-variant(@btn-consentz-color-dark; @btn-consentz-bg-color-dark; @btn-consentz-border-dark);
        }

        .glyphicon-search {
            display: none;
        }

        @media @sm {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 40px;
            font-size: 0;
            padding: 0;
            border-radius: 0 4px 4px 0;

            .glyphicon-search {
                display: inline-block;
                color: white;
                font-size: 14px;
            }
        }
    }

    #search_item {
        @media @sm {
            height: 36px;
        }
    }

    .logout,
    .logout a {
        color: @consentz-main-color;
    }

    #add-btn-multiple {
        width: 100%;
        @media @sm {
            width: 145px;
        }
    }

    .table-responsive {
        .table {
            margin: 0;
        }
    }
}

.hxc-modal {
    text-align: center;
    padding: 0!important;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }

    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        width: 460px;
    }

    .modal-content {
        border-radius: 15px;
        padding: 40px 40px 20px 40px;
    }

    h2 {
        margin-top: 0;
        border-bottom: 1px solid black;
        padding-bottom: 5px;
        margin-right: 74px;
        font-size: 20px;
    }

    .img-profile {
        position: absolute;
        right: 0;
        top: 0;
    }

    span {
        color: #8c8c8c;
        margin-left: 15px;
    }
}

.info-icon {
    display: inline-block;
    width: 17px;
    height: 17px;

}

.new-edit-product {
    h3 {
        margin-bottom: 20px;
    }

    .top-row {
        margin-bottom: 10px;

        label {
            margin-bottom: 0;
        }

        .form-group {
            margin: 0;
        }

        .input-browse-button {
            margin-top: 5px;
        }

        p {
            margin-bottom: 5px;
        }
    }

    .link-from-hxc-wr {
        #link-from-hxc {
            margin: 0;
            width: auto;
        }

        .hxc-stock-code {
            margin-right: 10px;
            height: 34px;
        }
    }
    .available-for-prescription{
        .form-group{
            margin-top: 25px;
        }
    }
}

.tnc-page {
    h2 {
        margin: 30px 0;
    }
}

.img-profile {
    &.default {
        background: url(../images/Clinic/product_icon.png) transparent no-repeat center;
    }
}
.img-room {
    height: 50px;
    padding-bottom: 14px;
}

.patient-div-date {
    margin-top: 8px;

    span {
        display: block;
        width: 100%;
        font-size: 14px!important;
    }

    img {
        width: 300px;
        height: 45px;
    }
}
.voucher-title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: white;
    background-color: @consentz-main-color;
}
.voucher-body {
    .voucher-code {
        font-size: 22px;
        font-weight: bold;
    }
    text-align: center;
    span {
        display: inline-block;
        width: 100%;
        &:first-child {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .voucher-address {
        display: none;
    }
}

#pull-chronoscan{
    width: 160px;
    height: 34px;
    margin-top: 25px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
#verify_invite {
    height: 34px;
}
.fc-nonbusiness{
    background: #bababa !important;
}

.fc-agendaDay-view .fc-event, .fc-agendaWeek-view .fc-event{
    border: 1px solid #fff!important;
}

.front-wrapper {
    padding: 100px 0;

    .form-header {
        display: flex;
        flex-direction: row;
        margin-bottom: 60px;

        .form-header-logo {
            width: 100px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .form-header-title {
            flex-grow: 1;

            h1 {
                text-align: center;
            }
        }
    }
}
.signature {
    position: relative;
    min-width: 275px;
    height: 200px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #f5f5f5;
    margin-bottom: 15px;

    .signature-pad {
        position: absolute;
        left: 0;
        top: 0;
        width:400px;
        height:200px;
    }
}
.navbar-default .hamburger .hamburger-bar, .navbar-default .hamburger::after, .navbar-default .hamburger::before {
    background: #fff;
}
.card .avatar {
    width: 130px;
    margin-bottom: 10px;
}
.avatar-barcode img {
    border-radius: 0 !important;
}
.page-header {
    .form-inline .form-group {
        margin-right: 0 !important;
    }
}
.full-height .site-action {
    z-index: 990;
}
.dropify-font-upload:before, .dropify-wrapper .dropify-message span.file-icon:before {
    content: none !important;
}
@media (max-width: 1199.98px) {
    .page-aside {
        width: 260px !important;
    }
    .page-aside-left .page-aside + .page-main {
        margin-left: 260px !important;
    }
    .page-aside-right .page-aside + .page-main {
        margin-right: 260px !important;
    }
    .page-aside-fixed.page-aside-left .site-footer {
        margin-left: 260px !important;
    }
    .page-aside-fixed.page-aside-right .site-footer {
        margin-right: 260px !important;
    }
}
.nav-tabs-line .nav-item.open .nav-link, .nav-tabs-line .nav-item.open .nav-link:focus, .nav-tabs-line .nav-item.open .nav-link:hover, .nav-tabs-line .nav-item.show .nav-link, .nav-tabs-line .nav-item.show .nav-link:focus, .nav-tabs-line .nav-item.show .nav-link:hover, .nav-tabs-line .nav-link.active, .nav-tabs-line .nav-link.active:focus, .nav-tabs-line .nav-link.active:hover {
    color: @consentz-fountain-blue !important;
    border-bottom: 2px solid @consentz-fountain-blue!important;
}
.loader-overlay {
    background: #ececec !important;
}
.loader-index > div {
    background: @consentz-main-color !important;
}
.glyphicon-plus {
    color: #fff;
}
.input-group .form-control {
    height: 34px;
}
.upload-download-link {
    color: #88cccd;
}

.trash {
  &:hover, &:focus {
    color: @btn-consentz-color-dark-blue!important;
  }
}
.site-menu-item a {
    color: @consentz-woodsmoke!important;
}
.nav-tabs .nav-link {
    color: @consentz-woodsmoke!important;
}
.nav-tabs-line .nav-link:hover {
    border-bottom-color: @consentz-woodsmoke!important;
}
.mb--45 {
    margin-bottom: -45px;
}
.mt--45 {
    margin-top: -45px;
}
body .navbar-fixed-top, .site-menubar {
    z-index: 1040!important;
}
.page-register .page-content .panel {
    width: 800px;
}
.panel-clinic-edit {
    .tab-pane {
        &.show {
            display: none !important;
        }
        &.active {
            display: block !important;
        }
    }
}
.consentz-title {
    font-weight: 400;
}

#captchaCode::placeholder {
    text-transform: none !important;
}