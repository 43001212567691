.div-margin {
  display: block;
  margin-bottom: 60px;
}
.div-margin-top {
  display: block;
  margin-top: 90px;
}
.div-margin-top-treatment {
  margin-top: 20px;
}
.div-margin-top-label {
  margin-top: 26px;
}
.div-margin-finish {
  display: block;
  //margin-top: 35%;
}
.help-info {
  //position: absolute;
  //bottom: 20%;
  //left: 36%;
  font-size: 18px;
}
.onboarding {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.onboarding-img {
  margin-top: 20px;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}

.step-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  overflow-x: auto;

  .step-item {
    text-align: center;
    width: 98px;

    a {
      color: #64bdbe;
      font-size: 1em;
    }

    a:hover, a:active, a:focus {
      text-decoration: none;
    }

    a.prev span {
      background: url("../../images/prev-step-item.png") no-repeat center;
    }

    a.active span {
      background: url("../../images/curr-step-item.png") center no-repeat;
    }

    .icon {
      display: inline-block;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background-color: #e2e2e2;
    }
  }

  .delimiter {
    border-bottom: 3px dashed #64bdbe;
    display: inline-block;
    width: 70px;
  }
}

.carousel-control {
  position: absolute;
  top: 50%;
  bottom: auto;
  width: 28px;
  height: 50px;
  margin-top: -25px;
  opacity: 0.7;

  transition: opacity 0.15s ease-in-out;


}

.carousel-control.right {
  right: -50px;
  color: black;
  background: url("../../images/slider-arrows.png") no-repeat scroll -271px 0px transparent;
  &:hover {
    background-position: -271px -50px;
  }
}

.carousel-control.left {
  left: -50px;
  color: black;
  background: url("../../images/slider-arrows.png") no-repeat scroll 0 0 transparent;
  &:hover {
    background-position: 0 -50px;
  }
}
.nav-carousel {
  position: absolute;
  bottom: 0;
}
#online-booking-active {
  font-weight: bold;
  padding: 10px;
  border-bottom: 2px solid #78c2c2;
  a {
    color: #78c2c2;
    text-transform: uppercase;
  }
  a:hover {
    color: #64bdbe;
    text-transform: uppercase;
    text-decoration: none;
  }
}
#online-booking {
  font-weight: bold;
  padding: 10px;
  a {
    color: #999;
    text-transform: uppercase;
  }
  a:hover {
    color: #64bdbe;
    text-transform: uppercase;
    text-decoration: none;
  }
}
#customise {
  a {
    cursor: pointer;
    color: #78c2c2;
  }
  a:hover {
    color: #64bdbe;
    text-decoration: none;
  }
}

.price-tier {
  background-color: #e8e8e8;

  ul {
    margin-bottom: 20px;
  }
}

.price-tier {
  border-top: 10px solid #88cccd;

  .title, .price {
    font-size: 25px;
    margin-bottom: 20px;
    color: #666;
  }

  padding: 20px 0;

  .title::first-letter {
    text-transform: uppercase;
  }

  .description {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .btn-get-started {
    padding: 10px 50px;
    border-radius: 20px;
  }

  .features {
    padding: 0 15px;
  }

  .list-group-item {
    color: #000;
  }

  .list-group-item-light {
    color: #999999;
    background-color: #fdfdfe;
  }
}

//.price-tier-starter {
//  border-top: 10px solid #a3c1cc;
//
//  .title {
//    color: #a3c1cc;
//  }
//
//  .price {
//    color: #a3c1cc;
//  }
//}
//
//.price-tier-professional {
//  border-top: 10px solid #44addb;
//
//  .title {
//    color: #44addb;
//  }
//
//  .price {
//    color: #44addb;
//  }
//}
//
//.price-tier-enterprise {
//  border-top: 10px solid #6f7886;
//
//  .title {
//    color: #6f7886;
//  }
//
//  .price {
//    color: #6f7886;
//  }
//}

.onboard-nav-btn {
  margin-bottom: 20px;
}
