.patient-pdf{
  .pdf-logo{
    width: 100px;
    height: 100px;
  }

  table{
    width: 100%;
  }

  table, th, td {
    border: 1px solid black;
  }

  th, td {
    padding: 3px 15px;
  }

  .photos{
    .photo{
      width: 24%;
      float: left;
      height: 350px;
      text-align: center;
      margin-bottom: 15px;
      margin-right: 1%;
      overflow: hidden;

      img{
        width: 100%;
        height: auto;
      }
    }
    //.photo div{
    //  background-size: cover!important;
    //}
  }
}
@media print {
  @page {
    margin: 0;
    size: portrait;
  }
}
.patient-answers-pdf {
  .pdf-logo {
    width: 170px;
    height: 170px;
  }
  margin: 25px;
  background-color: white;
  hr {
    border-top: 2px solid #000;
    margin-top: 0;
  }
  .answer-hr {
    border-top: 0;
    margin-top: 0;
    border-top: 2px solid #d7d7d7;
  }
  .questionnaire-title {
    text-transform: uppercase;
    margin-top: 0;
  }
  .answer-row {
    min-height: 30px;
  }
}
