#clinicReminder_reminderHeader, #clinicReminder_reminderFooter {
    width: 77%;
    float: left;
    margin-right: 10px;
}
.reminder-counter-wrapper {
    line-height: 34px;
    color: grey;
}
form[name="clinicReminder"], form[name="clinicReminderRecall"] {
    label {
        display: block;
    }
    .form-group:after {
        content: '';
        clear: both;
        display: table;
    }
    .reminder-item-title {
        float: left;
        margin-right: 5px;
        line-height: 34px;
    }
    .reminder-item-time {
        float: left;
        margin-right: 5px;
        width: 570px;
        .form-group {
            float: left;
            width: 48%;
            margin-right: 5px;
            margin-left: 5px;
        }

    }
    .remove-item {
        float: left;
    }
    .button-new-reminder {
        .btn, .new-reminder {
            float: left;
        }
        .new-reminder {
            line-height: 14px;
            color: #333;
            margin-left: 5px;
            margin-top: 5px;
        }
    }
}
