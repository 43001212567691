.treatment-pdf{

  @media print {
    @page { margin: 55px; }
  }

  .pdf-logo{
    width: 50px;
    height: 50px;
  }
  .title{
    padding-left: 15px;
  }
  .address p{
    text-align: right;
    margin-bottom: 5px !important;
  }
  .pdf-plan {
    margin-top: 20px;

    .plan-title {
      text-align: center;
    }

    h2{
      text-align: center;
    }
    table{
      border-collapse: collapse;
      width: 100%;

      th, td {
        border: 2px solid #9a9a9a;
      }

      td {
        width: 25%;
        padding: 5px;
      }

      td:nth-child(odd){
        background: #d9d9d9 !important;
      }
      //td:nth-child(1){
      //  border: 2px solid #d9d9d9 !important;
      //}
      //td:nth-child(2){
      //  border-left: none !important;
      //}
    }
  }
  .treatment-plan-entries{
    margin-top: 20px;

    .entrie-body{
      border: 1px solid black;
      padding: 5px;

      .price{
        padding: 10px 0 20px;
      }
    }
    .prescription-table table{
      width: 100%;
      border: none;

      td {
        width: 50%;
        padding: 5px;
      }
    }

    .total{
      width: 40%;
      border: 2px solid #9a9a9a;
      text-align: right;
      color: black;
      padding-top: 15px;
      padding-left: 25px;
      padding-right: 25px;
      margin-top: 40px;
    }
  }
  .info-block{
    margin-top: 10px;

    .declaration-title{
      font-size: 24px;
    }
  }
  .signature-block{
    margin-top: 5px;

    p{
      //line-height: 80px;
      font-size: 14px;
      //width: 50%;
    }
    .signature{
      width: 100%;
      background: #d9d9d9 !important;
      border: 2px solid #6c6c6c;
      height: 80px;
    }
  }
  .signature-name{
    margin-top: 20px;
    font-size: 16px;
  }
  .footer-block{
    margin-top: 120px;

    div{
      width: 33%;
      height: 70px;
      text-align: center;
      margin: auto;
      line-height: 70px;
      font-size: 18px;

      p{
        width: 100%;
        text-align: right;
      }
    }
    div:first-child{
      text-align: left;
    }
    div:last-child{
      line-height: 10px;
    }
  }
}

.container-treatment-pdf {
  page-break-after: always;
}