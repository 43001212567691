.tabulated-content-block {
    padding: 30px 80px;
    table {
        thead {
            tr {
                td {

                }
            }
        }
        tbody {
            tr {
                td {

                }
            }
        }
    }
    .pagination {
        text-align: center;
    }
}