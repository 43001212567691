header {
    .navbar {
        border: none;
    }
    .top-clinic-select-wrapper {
        margin-top: -5px;
        .container-1 {
            padding: 0;
            .top-clinic-select {
                //padding-top: 30px;
                .bootstrap-select {
                    width: auto;
                }
                .clinic-name-top {
                    color: @consentz-main-color;
                    font-size: 20px;
                }
            }
            button {
                border: none;
                color: @consentz-main-color;
                font-size: 20px;
                background-color: transparent;
                .bs-caret {
                    margin-left: 5px;
                    .caret {
                        border-width: 8px;
                    }
                }

                &:focus {
                    outline: none !important;
                    background-color: transparent;
                    color: @consentz-main-color;
                    border: none;
                }
                &:hover {
                    background-color: transparent;
                    color: @consentz-main-color;
                    border: none;
                }
            }
            .open {
                button, button:hover {
                    background-color: transparent;
                    color: @consentz-main-color;
                    border: none;
                    box-shadow: none;
                }
            }
        }
    }
}
.profile-name {
    margin-right: 13px;
}