.checkbox {
  input.toggle-registration {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/tabs_2.png");

    }
  }
  input.toggle-registration + label {
    background: url("../../images/tabs_1.png") no-repeat scroll left center transparent;
    background-size: 320px;
    padding-left: 330px !important;
    line-height: 16px;
    //min-height: 60px;
    width: 100%;
    font-size: 10px;
    color: #333333 !important;
  }
  input.invoice-prepay + label {
    background: url("../../images/switcher_off.png") no-repeat scroll left center transparent;
    padding-left: 70px !important;
    line-height: 32px;
    min-height: 32px;
  }
  input.invoice-prepay {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-enabled-vat + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 91%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-vat {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.checklist-force + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    background-size: 30px;
    margin-left: -40px;
  }
  input.checklist-force {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
      background-size: 30px;
    }
  }

  input.clinic-enabled-header + label,
  input.toggle-view + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 65%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-header,
  input.toggle-view {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-enabled-traffic-light + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 82%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-traffic-light {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.treatment-enabled-required-fields + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 67%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-required-fields + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 42%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-required-fields, input.treatment-enabled-required-fields {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-enabled-double-booking + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 87.5%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-double-booking {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-required-questionnaires + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 81%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-required-questionnaires {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-required-confirmation + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 82.5%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-required-confirmation {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-fixed-discount-amount + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 82.5%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-fixed-discount-amount {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-enabled-patient-required-fields + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 69%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-patient-required-fields {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-enabled-prospect-required-fields + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 57.5%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-prospect-required-fields {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-enabled-postcode + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 80.5%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-postcode {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-enabled-chronoscan + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 84.5%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-chronoscan {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-enabled-prospect-form + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 78.5%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-enabled-prospect-form {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }

  input.clinic-shared-patients + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-left: 0 !important;
    padding-right: 67%;
    line-height: 32px;
    min-height: 32px;
  }
  input.clinic-shared-patients {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }
  input.ios-switch + label {
    background: url("../../images/switcher_off.png") no-repeat scroll left center transparent;
    padding-left: 70px !important;
    line-height: 32px;
    min-height: 32px;
  }
  input.ios-switch {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
    }
  }
  input.goods-received + label {
    background: url("../../images/Clinic/stock_status_off.png") no-repeat scroll left center transparent;
    line-height: 32px;
    min-height: 32px;
  }
  input.goods-received {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/Clinic/stock_status_on.png");
    }
  }
  input.calendarPrivate + label {
    background: url("../../images/lock-open-3.png") no-repeat scroll left center transparent;
    line-height: 32px;
    min-height: 32px;
    background-size: 14px;
  }
  input.calendarPrivate {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/lock-closed-1.png");
    }
  }
  input.calendarPractitioner + label {
    background: url("../../images/three_men_(1).png") no-repeat scroll left center transparent;
    background-size: 19px;
    margin-left: 10px;
  }
  input.calendarPractitioner {
    position: absolute!important;
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/one_man_(1).png");
    }
  }
  input.calendarRoom + label {
    background: url("../../images/room_column_header_icon.png") no-repeat scroll left center transparent;
    background-size: 20px;
  }
  input.calendarRoom {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/room_column_header_icon.png");
    }
  }
  input.calendarFullScreen + label {
    background: url("../../images/make_full_screen_icon.png") no-repeat scroll left center transparent;
    background-size: 14px;
  }
  input.calendarFullScreen {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/make_full_screen_icon.png");
    }
  }
  input.reportAllClinic + label {
    background: url("../../images/BUILDINGs@2x.png") no-repeat scroll left center transparent;
    line-height: 36px;
    min-height: 36px;
    background-size: 36px;
  }
  input.reportAllClinic {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/BUILDING@2x.png");
    }
  }
  input.treatmentInfoToggle + label {
    background: url("../../images/switcher_off.png") no-repeat scroll right center transparent;
    padding-right: 60px !important;
    line-height: 32px;
    min-height: 32px;
    background-size: 32px;
    margin-left: -38px!important;
    font-weight: bold;
  }
  input.treatmentInfoToggle {
    left: -9999px;
    &:checked + label {
      background-image: url("../../images/switcher_on.png");
      background-size: 32px;
    }
  }
  input.btnFilter + label {
    display: inline-block;
    width: 33%;
    float: left;
    margin: 0!important;
    padding: 0!important;
    text-align: center;
    font-size: 13px;
    background: none;
    height: 34px;
    background-color: #f6f8f8;
    border: 1px solid #e0e0e0;
  }
  input.btnFilter.btn-middle + label {
    border-right: none;
    border-left: none;
  }
  input.btnFilter {
    left: -9999px;
    &:checked + label {
      background-color: @consentz-main-color;
      color: white;
    }
  }
  input#btnTreatment + label {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  input#btnPractitioner + label {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.checkbox-calendar {
  margin-top: 0!important;
  margin-bottom: 0!important;
}

#address-list li{
  cursor: pointer;
}
