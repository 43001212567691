label {
    display: inline-block;
    max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
    //margin-bottom: 5px;
    font-weight: 400;
    color: @label-color;
    .custom-upload {
        float: none;
        position: relative;
        text-align: left;
        span {
            position: absolute;
            top: 50%;
            margin-top: -17px;
        }
    }
}
.reference-prefix{
  padding-top: 31px;
}

.hidden-elem {
  display: none;
}
.question-form {
    padding-left: 0;
    min-height: 34px;
    & .checkbox {
        padding-left: 80%;
        margin-bottom: 23px;
    }
}
.total {
    color: #78c2c2;
}
[data-hidden] {
    input[type="file"]{
        position: absolute;
        left: -9999px;
    }
}
.checkbox {
  label {
    background: url("../../images/checkbox.png") no-repeat scroll left center transparent;
    padding-left: 40px !important;
    line-height: 32px;
    min-height: 32px;

  }
  input {
    left: -9999px;

    &:checked + label {
      background-image: url("../../images/checkbox_checked_new.png");

    }
  }
    .switchery {
      float: right;
    }
}
.new-edit-product {
  .checkbox {
    .switchery {
      float: none;
      margin-left: 15px;
    }
  }
}

.question-switch {
  .checkbox {
    .switchery {
      float: left;
      margin-right: 15px;
    }
  }
}

.invoices-filter {
  .checkbox {
    .switchery {
      float: left;
    }
    label {
      float: right;
      margin-left: 10px;
    }
  }
}
.toggle-small-checkbox {
  .checkbox {
    .switchery {
      float: left;
    }

    label {
      float: left;
      padding-left: 0 !important;
      background: none;
      margin-left: 10px;
      margin-top: -5px;
    }
  }
}
a {
  .md-plus.link-add {
    color: @consentz-main-color;
    font-size: 28px;
  }
}

.toggle-checkbox{
    label {
        float: left;
        padding-left: 0 !important;
        background: none;
    }
}
label.error {
    display: block;
    float: none; color: red;
    vertical-align: top;
}
input.error, button.error {
    border-color: red;
}
.locked-div {
    display: inline-block;
    width: 27%;
    .checkbox label {
        line-height: inherit;
    }
    //float: right;
}
.verify {
    span {
        padding-top: 15px;
        button {
            height: 30px;
        }
    }
  .form-control {
    border-radius: 4px 0 0 4px !important;
  }
}
.custom-line {
    line-height: 32px;
}
.chbox-appointment {
    .input-group {
        float: left;
        margin-right: 10px;
        margin-left: 10px;
    }
  .checkbox {
    margin-top: 0;
  }
}
.modal-footer {
    .text-center {
        .btn+.btn {
            margin-bottom: 15px!important;
        }
    }
}
.separator-form {
    margin-bottom: 30px;
    margin-top: 45px;
    border-bottom: 3px solid @consentz-main-color;
}
.info-form-link {
    position: relative;
    a {
        position: absolute;
        white-space: nowrap;
        top: -20px;
        right: 0;
    }
}
.manual-height {
    height: 40px;
}
.special-form-design {
  color: @consentz-woodsmoke;
    .form-group {
        margin-bottom: 10px;
    }
    label, textarea, select {
      color: @consentz-woodsmoke;
    }
    label, span, textarea, select, ul {
        font-size: 12px;
    }
    input {
        height: 30px;
        font-size: 12px;
    }
    .phone-div {
        height: 34px;
    }
    .div-info {
        margin-top: 15px;
    }
}
.form-text-size {
    font-size: 12px;
}
#appointment_endType {
    .radio {
        label {
            padding-top: 10px;
        }
    }
}
//#appointment_start.datetime, #appointment_end.datetime, #google_event_start.datetime, #google_event_end.datetime, #appointment_ends.datetime {
//    text-transform: capitalize;
//}
#clinic_periodStoredData {
    display: inline-block;
    width: 6%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #78c2c2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #78c2c2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tag-field {
  //margin-top: 199px;

  label {
    font-size: 12px;
    text-align: center;
  }

  .list-container {
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;

    .btn-link {
      font-size: 12px;
      color: #78c2c2;;
    }

    .filter-option {
      font-size: 13px;
    }

    .form-group{
      margin-bottom: 0;

      button {
        padding: 2px;

        .caret{
          right: 4px;
        }
      }
    }

    h5 {
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.status-form {
  position: absolute;
  margin: -17px 0 0;
  width: 160px;
}

.register-checkboxes{
  .checkbox {
    padding: 6px 10px;
    border: 1px solid #e3e3e3;
    background-color: #fff;
    margin-bottom: 4px;
  }
}

ul.collection-list {
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 20px;
  }
}
#fileName {
  margin-top: -32px;
  position: relative;
  margin-right: 20px;
}

.survey-sent-form-container, .post-apt-form-container{
  background: white !important;
}
.survey-form {
  input[type=checkbox] {
    margin: 0;
  }

  & > .form-group {
    margin-bottom: 40px;
    overflow: hidden;

    & > label {
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .form-control[disabled] {
    background: transparent;
    border: none;
    padding: 6px 0;
    cursor: default;
    box-shadow: none;
  }

  .form-questionnaire,
  .form-file {
    margin-bottom: 30px;
  }
  .form-file {
    .checkbox{
      padding-top: 5px;
      padding-left: 30px;
      text-align: center;
    }
    //.form-file-agreement-checkboxes{
    //  float: left;
    //}

  }
  //.form-questionnaire {
  //  [id*="_questions"]:not(.form-question) {
  //    counter-reset: line-number;
  //
  //    & > .form-group {
  //      display: flex;
  //      flex-direction: row;
  //      counter-increment: line-number;
  //
  //      &:before {
  //        content: counter(line-number)". ";
  //        margin-right: 5px;
  //        padding: 6px 0;
  //      }
  //    }
  //  }
  //}

  .form-question {
    flex-grow: 1;

    & > .form-group {
      margin-bottom: 5px;
      overflow: hidden;
    }
    .row{
      .question-choice-container{
        display: flex;
        flex-wrap: wrap;
        .question-choice{
          display: flex;
          flex-direction: column;
        }
      }
    }

    .checkbox {
      padding-top: 7px;
      padding-bottom: 7px;
      width: 100%;
    }
    textarea {
      background-color: #fff !important;
      border: 1px solid #ccc !important;
      border-radius: 4px !important;
    }
  }

  .form-questionnaire-title {
    font-size: 18px;
  }

  .additional-info-wrapper {
    display: none;

    &.active {
      display: block;
    }
  }

  .checkbox {
    @media (min-width: 768px) {
      display: inline-flex;
      float: left;
      width: 50%;
    }

    @media (min-width: 992px) {
      width: 33.3%;
    }

    @media (min-width: 1200px) {
      width: 25%;
    }

    label {
      background: none;
      line-height: 1;
      min-height: auto;
    }

    input:checked+label {
      background: none;
    }
  }

  .checkbox input[type=checkbox],
  .checkbox-inline input[type=checkbox],
  .radio input[type=radio],
  .radio-inline input[type=radio] {
    position: static;
    margin-left: 0;
  }

  .radio {
    display: inline-flex;
    float: left;
  }

  .checkbox,
  .radio {
    margin: 0;
    padding-right: 20px;

    label {
      padding-left: 15px !important;
    }
  }

  .checkbox+.checkbox,
  .radio+.radio {
    margin-top: 0;
  }

  #form_patient {
    .form-patient-group-inline .form-group {
      display: flex;
      flex-direction: row;

      label {
        padding: 6px 0;
        margin: 0 5px 0 0;
      }

      input {
        flex-grow: 1;
      }
    }

    #form_patient_editableFields {
      @media (min-width: 1200px) {
        display: flex;
        flex-direction: row;

        & > .form-group {
          flex-grow: 1;

          &:not(:last-child) {
            padding-right: 15px;
          }
        }
      }
    }
  }
}
.checkbox {
  margin-top: 20px;
}
.form-group-search {
  width: 80%;
  .input-group {
    width: 100%;
  }
}
.form-inline .form-group-search .input-group .input-group-btn {
  width: 1%;
}

.form-create-appointment {
  .checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    margin-left: 0;
  }
}

.promocode-alert {
  color: #a3afb7;
  font-weight: 300;
  font-size: 10px;
  text-align: right;
}
