.table {
    tbody {
        tr {
            border-top: 1px solid @tbl-border-default-color;
            td {
                border: none;
                padding: 8px;
                vertical-align: middle;
                .modal.fade {
                    a {
                        color: inherit;
                    }
                }
                a {
                    color: @consentz-fountain-blue;
                    text-decoration: none;
                    font-weight: 400;

                    &:hover {
                        color: @btn-consentz-color-dark-blue;
                        text-decoration: underline;
                    }
                }
                .btn-consentz-light {
                    &:hover {
                        text-decoration: none;
                    }
                }
                span.glyphicon {
                    color: @consentz-fountain-blue;
                    text-decoration: none;
                    font-weight: 400;
                }
                .checkbox {
                    margin: 0;
                }
            }
        }
    }
    thead {
        tr {
            th {
                border: none;
                padding: 13px 10px;
                color: #333333;
                &.table-actions {
                    vertical-align: middle;
                    font-size: 18px;
                        padding-bottom: 5px;
                }
            }
        }
    }
    color: @consentz-woodsmoke!important;
}
.table-appointment {
    tbody {
        tr {
            td {
                padding: 5px 5px !important;
            }
        }
    }
    thead {
        tr {
            th {
                padding: 5px 5px !important;
            }
        }
    }
}
.price-list-item tbody tr td {
    padding: 8px;
}
.table-actions {
    width: 50px;
    a {
        &:hover, span:hover {
            text-decoration: none;
            color: @btn-default-hover;
        }
    }
}
.table-responsive {
    .table {
        margin-bottom: 0;
    }
}
.table-date {
    min-width: 14%;
}
.table-description {
    width: 20%;
}
.table-person {
    min-width: 17%;
}
.dd-important-info {
    display: flex;
}
.dd-question {
    display: block;
    width: 50%;
    min-height: 28px;
    vertical-align: middle;
}
.dd-answer {
    display: block;
    width: 50%;
    text-align: right;
    min-height: 28px;
    vertical-align: middle;
}
th {
    a {
        color: #333;
        padding-right: 18px;
        &.desc {
            background: url("../../images/sorting-desc.png") no-repeat right center ;
            background-size: 8px 16px;
        }
        &.asc {
            background: url("../../images/sorting-asc.png") no-repeat right center ;
            background-size: 8px 16px;
        }
        &.sortable{
            background: url("../../images/sorting-none.png") no-repeat right center ;
            background-size: 8px 16px;
        }
        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }
}
#treatments-per-practitioner-table {
    td {
        width: 12%;
    }
    th {
        width: 12%;
    }
    //td:first-child {
    //    width: 4%;
    //}
    table {
        td {
            width: 10%;
            //padding: 8px 18px;
        }
    }
}
#average-spend-per-patient-table {
    td {
        width: 8%;
    }
    th {
        width: 8%;
    }
    //td:first-child {
    //    width: 4%;
    //}
    table {
        td {
            width: 8%;
            //padding: 8px 19px;
        }
    }
}
.table-details {
    table.table {
        tbody, thead {
            td, th {
                font-size: 11px;
                padding: 3px 5px !important;
            }
        }
    }
}
.table-sticky {
    height: 70vh;
    margin-top: 20px;
    table.table {
        margin-top: -1px!important;
        margin-bottom: 0!important;
        max-width: none!important;
    }
}

.table tbody tr td form[name="priceList"]  {
    .bootstrap-select {
        a {
            color: black;
        }
        button.dropdown-toggle {
            height: 40px;
        }
    }
    input[type="text"] {
        padding: 4px;
    }
}
.categoryTable {
    .category {
        a {
            text-decoration-line: underline;
            padding-right: 0;
        }
    }
    th.category[data-on="true"] {
        :before {
            content: " + ";
        }
        white-space: nowrap;
    }
    th.category[data-on="false"] {
        :before {
            content: " - ";
        }
        white-space: nowrap;
    }
}
.bordered-div-daySheet {
    &.active-border {
        border: 1px solid transparent;
    }
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 5px;
    width: 100%;

    h3 {
        background: #fff;
        margin: 0;
        padding: 8px 20px 8px 0;
        font-size: 16px;
        font-weight: 300;
        border-bottom: 1px solid #ececec;

        &.hidden-actions-td {
            .edit-day-sheet,
            .delete-day-sheet {
                padding: 0 5px;

                span.btn-link {
                    font-size: 16px;
                }
            }

            &:hover .hidden-actions {
                display: inline-block !important;
            }
        }
    }

    .table-with-mh {
        //max-height: 200px;
        overflow: auto;
    }

    .table {
        border: none;

        tbody {
            tr {
                padding: 10px 0;

                td {
                    padding: 12px 20px;

                    .form-group {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .bootstrap-select {
        ul {
            li {
                a {
                    color: black;
                }
            }
        }
        //.btn.dropdown-toggle {
        //    padding: 3px 6px;
        //}
    }
}
