.btn-consentz-dark {
    .button-variant(@btn-consentz-color-dark; @btn-consentz-bg-color-dark; @btn-consentz-border-dark);
    &:hover {
        .button-variant(@btn-consentz-color-light; @btn-consentz-bg-color-light; @btn-consentz-border-light);
    }
}

.btn-consentz-light {
    .button-variant(@btn-consentz-color-light; @btn-consentz-bg-color-light; @btn-consentz-border-light);
    &:hover {
        .button-variant(@btn-consentz-color-light; @btn-consentz-bg-color-dark-blue; @btn-consentz-border-dark-blue);
    }
}

.btn-link-dark {
    .button-variant(@btn-consentz-bg-color-dark; transparent; transparent);
    &:hover {
        .button-variant(@btn-consentz-bg-color-light; transparent; transparent);
    }
}

.btn-link-light {
    .button-variant(@btn-consentz-bg-color-light; transparent; transparent);
    &:hover {
        .button-variant(@btn-consentz-bg-color-dark; transparent; transparent);
    }
}

.btn-patient-save {
    width: 100%;
}

.btn-form {
    width: 145px;
    text-align: center;
}
.btn-form-lg {
    width: 170px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
}
.btn-add-clinic {
    margin: 0 5px;
    border-radius: 0;
}
.btn-search {
    height: 34px;
}
.btn-invoices {
    width: 33.33333333%;
}
.btn-form {
    margin-top: 10px;
    margin-bottom: 15px;
}
.btn-form-invoice {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 150px;
    text-align: center;
}
.form-invoice {
    margin-top: 15px;
}
.btn-profile {
    margin-bottom: 40px;
}

.btn-tabs {
    //width: 50%;
    a {
        text-align: center;
        font-size: large;
        background-color: #EBEBEB;
    }
}


button {
    &.trash, &.pencil {
        border: 0 solid transparent;
        background-color: transparent;
        padding: 0;
        &:hover {
            color: @btn-consentz-color-dark-blue;
            text-decoration: underline;
        }
    }
    &.btn-template {
        width: 80px;
    }
}
.btn {
    &.btn-consentz-add {
        background: @btn-consentz-plus-color;
        color: white;
    }
    &.btn-consentz-search {
        background: @consentz-main-color;
        color: white;
        height: 34px;
    }
    &.btn-export {
        text-decoration: underline;
        margin-right: 15px;
        color: @consentz-main-color;
    }
}
.btn-export {
    color: @consentz-main-color;
}
.export-import-wrapper {
    display: inline-table;
    vertical-align: middle;
    //padding: 0 10px;
    span {
        display: inline-block;
        width: 34px;
        height: 34px;
        cursor: pointer;
        margin: 0 5px -4px 5px;
    }
}
.btn-filter-dashboard {
    width: 24%;
    margin: 0 auto;
    margin-left: 1px;
    text-align: center;
}
.btn-add-item-invoice {
    margin-top: 26px;
    margin-left: 40%;
}
.betterInputFile .input-group-btn .btn{
    width: 150px;
}
.button-send {
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: #88cccd;
    border-color: #64bdbe;
    width: 40px;
    height: 34px;
    color: white;
    span {
        margin: 5px 0 0 -5px;
        width: 40px;
    }
    &:hover, &:focus {
        color: white;
    }
}
.btn-c-upload {
    color: #fff;
    font-size: 22px;
}
.button-upload {
    display: inline-block;
    font-size: 28px;
    padding: 2px 15px;
}
.button-download {
    display: inline-block;
    font-size: 28px;
    padding: 2px 15px;
}
.button-copy-profile-calendar {
    display: inline-block;
    width: 34px;
    background: @consentz-main-color url("../../images/ic_copy_white@1x.png") no-repeat scroll center;
    background-size: 22px;
    margin-top: 10px;
    border: 1px solid @consentz-main-color;
    border-radius: 0;
}
.button-email-profile-calendar {
    display: inline-block;
    width: 34px;
    height: 30px;
    background: @consentz-main-color url("../../images/ic_email_white@1x.png") no-repeat scroll center;
    background-size: 22px;
    margin-top: 10px;
    border: 1px solid @consentz-main-color;
    border-radius: 0 4px 4px 0;
}
.button-download-add-margin {
    margin-left: 10px;
}
.input-browse-button {
    width: 110px;
    height: 34px;
    margin-top: 10px;
    color: white;
}
.input-browse-button-img {
    cursor: pointer;
}
.edit-day-sheet {
    border: none;
    span.btn-link {
        color: grey;
    }
}
.delete-day-sheet {
    border: none;
    span.btn-link {
        color: grey;
    }
}
.status-sort-url {
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 5px;
        border: 4px solid transparent;
        border-top-color: black;
    }
}
.button-voucher {
    display: inline-block;
    width: 24px;
    min-height: 24px;
    background: url("../../images/gift_voucher_icon@2x.png") no-repeat scroll center transparent;
    background-size: 24px;
}
