
.dashboard-table-report {
  background-color: white;
  //overflow: auto;
  padding: 5px;
}
.button-show-more-report {
  clear: both;
  text-align: center;
  margin-top: 20px;
}
.buttons-dashbord-report {
  clear: both;
  margin-top: 20px;
  margin-bottom: 20px;
}
.button-filter-open-report {
  display: block;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
  position: relative;
  min-height: 50px;
  .button-consentz-open-filter-report {
    position: absolute;
    left: 3px;
    display: inline-block;
    width: 29px;
    height: 29px;
    cursor: pointer;
    background: url("../../images/charts.png") no-repeat scroll center transparent;
    background-size: 29px 29px;
  }
  .button-consentz-open-filter-export {
    position: absolute;
    left: 33px;
    display: inline-block;
    margin-left: 5px;
    width: 29px;
    height: 29px;
    cursor: pointer;
    background: url("../../images/info-icon_copy.png") no-repeat scroll center transparent;
    background-size: 29px 29px;
  }
  .button-consentz-open-organisation-level {
    position: absolute;
    left: 68px;
    display: inline-block;
    margin-left: 5px;
    width: 29px;
    height: 29px;
    cursor: pointer;
    background: url("../../images/info-icon_copy.png") no-repeat scroll center transparent;
    background-size: 29px 29px;
  }
  .title-chart {
    display: inline-block;
    width: 100%;
    padding: 0 75px;
    text-align: center;
    font-size: 16px;
  }
}
.dashboard-wrapper-report {
  position: relative;
  .text-center {
    clear: both;
    padding-top: 50px;
  }
  .dashboard-filter-wrapper-report {
    position: absolute;
    right: -50px;
    top: 0;
    width: 0;
    overflow: hidden;
    transition: 0.5s;
    z-index: 1041;
    background-color: @filter-bg-color;
    &.opened {
      width: 420px;
    }
    .close-form-new-report {
      position: absolute;
      right: 0;
      top: 15px;
      padding: 5px;
      font-size: 22px;
      color: #444444;
      cursor: pointer;
    }
    .dashboard-div-report {
      padding: 50px 15px 20px;
      .nav-tabs {
        background: transparent;
        li {
          width: 50%;
          a {
            width: 100%;
            padding: 10px 0;
            text-align: center;
            background: transparent;
            text-transform: none;
          }
          &.active {
            a {
              border-bottom: 1px solid @consentz-main-color;
              height: auto;
            }
          }
        }
      }
      .settings-checkboxes-wrapper-report {
        height: 400px;
        overflow-y: scroll;
      }
    }
  }

}
@media (max-width: 1200px) {
  .dashboard-div-report {
    padding: 10px 5px 10px;
    div {
      table {
        font-size: smaller;
      }
      .table tbody tr td, .table thead tr th {
        padding: 8px;
      }
    }
  }
}

.div-height {
  height: 350px;
}
.h-color {
  color: @consentz-main-color;
}
.filter-modal-body {
  padding-left: 45px;
  padding-right: 45px;
}
.filter-modal-footer {
  padding-right: 45px;
}

#setting_reportValue, #setting_value {
  .choice_category {
    display: block;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .report-choice {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    .checkbox {
      margin-top: auto;
    }
  }
}
.reports-action-btn {
  display: inline-block;
  width: 40%;
  float: right;
  text-align: right;
  padding-top: 15px;
  padding-right: 30px;
}
.generate-reports {
  display: inline-block;
  width: 60%;
}
#render-block-report {
  .col-sm-4 {
    margin-top: 20px;
  }
}
.details-link-report {
  p {
    display: inline-block;
  }
  img {
    padding-left: 10px !important;
    width: 46px;
  }
}
.pounds-align {
  text-align: right;
}