#ps-payment-terminal,
#ps-refund-terminal {
  h4 {
    font-size: 28px;
    margin: 20px 0;
  }

  .terminal-content {
    margin: 20px 0 10px;

    p {
      font-size: 20px;
      margin: 0 0 20px;
    }
  }

  span.terminal-icon {
    display: inline-block;

    &.terminal {
      width: 58px;
      height: 58px;
      background: url("../../images/info-icon_copy.png") no-repeat center;
    }
  }

  .terminal-content {
    padding: 0 20px;
  }

  .signature {
    width: 100%;

    &.yes {
      margin-top: 20px;
    }
  }

  .submitting {
    &.yes {
      margin-left: 30px;
    }
  }
}

@media (min-width: 768px) {
  .ps-wr {
    padding: 0 20px;
  }

  #ps-payment-terminal,
  #ps-refund-terminal {
    .terminal-content {
      padding: 0;
    }

    .signature {
      min-width: 100px;
      width: auto;

      &.yes {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }

  .modal-dialog.paymentsense-report {
    width: 100%;
    max-width: 1000px;
  }
}