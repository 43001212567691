.page-login-v3 {
  &:before {
    background: @consentz-main-color!important;
    background-image: linear-gradient(to bottom, @consentz-main-color 0%, @consentz-fountain-blue 100%);
  }
  .page-content {
     margin: auto;
     padding: 30px;
     padding-top: 0;
     margin-top: 0;
   }
  .form-control {
    &:focus, &.focus, &:not(.empty) {
      ~ .floating-label {
        color: @consentz-fountain-blue!important;
      }
    }
    &:-webkit-autofill {
      ~ .floating-label {
        color: @consentz-fountain-blue!important;
      }
    }
  }
}
